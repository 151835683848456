import { Colors, Lightning } from "@lightningjs/sdk";

export default class Background extends Lightning.Component {
    static _template() {
        return {
            Background: {
                rect: true,
                w: 1920,
                h: 1080,
                zIndex: -900,
                color: Colors("pageBackground").get()
            }
        };
    }

    _init() {
        this.listeners = {
            clearBackground: () => {
                this._setBackground(true);
            },
            showBackground: () => {
                this._setBackground(false);
            }
        };
    }

    _attach() {
        ["clearBackground", "showBackground"].forEach(event => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detach() {
        ["clearBackground", "showBackground"].forEach(event => {
            this.application.off(event, this.listeners[event]);
        });
    }

    _setBackground(clear) {
        this.tag("Background").patch({
            color: Colors("pageBackground")
                .alpha(clear ? 0 : 1)
                .get()
        });
    }
}
