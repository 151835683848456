import { Lightning, Router } from "@lightningjs/sdk";
import Button from "../../Button/Button";
import TranslatableText from "../../TranslatableText/TranslatableText";
import { TERMS_URL } from "../../../lib/utils";

export default class AgreementPopup extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            w: 584,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            flexItem: { grow: 1 },
            Label: {
                type: TranslatableText,
                key: "agreementTitle",
                text: {
                    fontSize: 32
                }
            },
            Info: {
                w: 584,
                flexItem: {
                    marginBottom: 48,
                    marginTop: 48
                },
                key: "agreementTitle",
                text: {
                    text: TERMS_URL,
                    fontSize: 24,
                    lineHeight: 36
                }
            },
            Btn: {
                collision: true,
                h: 84,
                w: 584,
                zIndex: 92,
                Close: {
                    collision: true,
                    zIndex: 92,
                    x: 0,
                    type: Button,
                    h: 84,
                    w: 584,
                    label: "close",
                    signals: {
                        handleClick: "_handleEnter"
                    }
                }
            }
        };
    }

    _getFocused() {
        return this.tag("Close");
    }

    _handleEnter() {
        Router.focusPage();
    }
}
