import { Lightning } from "@lightningjs/sdk";

export default class LoadingCircle extends Lightning.Component {
    static _template() {
        return {
            x: 1920 / 2 - 86 / 2,
            y: 1080 / 2 - 86 / 2,
            Loading: {
                h: 86,
                w: 86,
                texture: Lightning.Tools.getSvgTexture(
                    `data:image/svg+xml,${encodeURIComponent(`<svg width="94" height="94" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M90 47C90 70.7482 70.7482 90 47 90C23.2518 90 4 70.7482 4 47C4 23.2518 23.2518 4 47 4" stroke="#FAC200" stroke-width="8" stroke-linecap="round"/>
                        </svg>`)}`,
                    86,
                    86
                )
            }
        };
    }

    set isAbsolute(value) {
        if (!value) {
            this.patch({
                x: 0,
                y: 0,
                h: 86,
                w: 86
            });
        }
    }

    _init() {
        this._animation = this.tag("Loading").animation({
            duration: 1.2,
            repeat: -1, // infinite
            // actions: [{ p: "rotation", v: { 0: 0, 0.25: 180, 0.5: 360, 0.75: -180, 1: 0 } }]
            actions: [{ p: "rotation", v: { 0: 0, 1: Math.PI * 2 } }]
        });
    }

    _active() {
        this._animation.start();
    }

    _inactive() {
        this._animation.stop();
    }
}
