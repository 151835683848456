import { Lightning, Router } from "@lightningjs/sdk";
import SettingsItem from "./components/SettingsItem";
import SubscriptionQRPopup from "./components/SubscriptionQRPopup";

export default class Subscription extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            Item: {
                collision: true,
                type: SettingsItem,
                label: "subscription",
                icon: "icons/subscription.png"
            }
        };
    }

    set widgets(value) {
        this._widgets = value;
    }

    set loggedIn(value) {
        this._loggedIn = value;
    }

    _getFocused() {
        return this.tag("Item");
    }

    _init() {
        this.patch({
            Item: {
                w: this.w,
                h: this.h
            }
        });
    }

    _handleEnter() {
        this._widgets.modal.open([
            {
                type: SubscriptionQRPopup
            }
        ]);

        Router.focusWidget("Modal");
    }
}
