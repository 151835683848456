import { Launch } from "@lightningjs/sdk";
import App from "./App.js";

// TODO: uncomment before generating for samsung, lg, anf others.
//  keep commented for comcast

import "./platforms/polyfills.js";

import "./platforms/amazon.js";
import "./platforms/sentry.js";

import "./platforms/tizen.js";

export default function() {
    return Launch(App, ...arguments);
}
