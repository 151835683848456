import { Colors, Img, Lightning, Registry, Router, Utils } from "@lightningjs/sdk";

export default class Boot extends Lightning.Component {
    static _template() {
        return {
            w: 1920,
            h: 1080,
            rect: true,
            color: Colors("pageBackground").get(),
            Logo: {
                w: 1920,
                h: 1080,
                alpha: 0.01,
                texture: Img(Utils.asset("images/splashscreen.png")).cover(1920, 1080)
            }
        };
    }

    _init() {
        const animation = this.tag("Logo").animation({
            duration: 1,
            repeat: 0,
            stopMethod: "immediate",
            actions: [{ p: "alpha", v: { 0: 0, 1: 1 } }]
        });
        animation.start();

        this.tag("Logo").on("txLoaded", () => {
            Registry.setTimeout(() => {
                animation.stop();
                Router.resume();
            }, 1600);
        });
    }

    pageTransition() {
        return "crossFade";
    }
}
