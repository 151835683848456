import { Utils } from "@lightningjs/sdk";

import ClickableKey from "./ClickableKey";

export const KEY_DIMENSIONS = { h: 68, w: 77.2, padding: 0, fixed: true };
export default class IconKey extends ClickableKey {
    set icon(src) {
        this._icon = src;
        this.patch({
            Label: {
                x: (w) => w / 2,
                y: (h) => h / 2,
                mount: 0.5,
                w: 29,
                h: 24
            }
        });

        this._update();
    }

    get icon() {
        return this._icon;
    }

    set alignLeft(w) {
        this._alignLeft = w;

        setTimeout(() => {
            this.patch({
                Label: {
                    x: (w) => w,
                    mountX: 2
                }
            });
        }, 1);
    }

    get alignLeft() {
        return this._alignLeft;
    }

    _unfocus() {
        this._update();
    }

    _focus() {
        this._update();
    }

    _update() {
        if (!this.active) {
            //blocking update if not active.
            return;
        }
        const hasFocus = this.hasFocus();
        let { focused, unfocused = 0xff000000 } = this.backgroundColors;

        this.patch({
            Background: { color: hasFocus && focused ? focused : unfocused },
            Label: {
                src: Utils.asset(hasFocus ? `${this._icon}-active.png` : `${this._icon}.png`)
            }
        });
    }
}
