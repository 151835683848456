import { Lightning, Router } from "@lightningjs/sdk";
import Button from "../../Button/Button";
import TranslatableText from "../../TranslatableText/TranslatableText";

export default class LoggedInPopup extends Lightning.Component {
    static _template() {
        return {
            w: 584,
            collision: true,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            flexItem: { grow: 1 },
            Label: {
                type: TranslatableText,
                key: "loggedInTitle",
                text: {
                    fontSize: 32
                }
            },
            Info: {
                w: 584,
                flexItem: {
                    marginBottom: 48,
                    marginTop: 48
                },
                type: TranslatableText,
                key: "loggedInInfo",
                text: {
                    fontSize: 24,
                    lineHeight: 36,
                    textAlign: "left"
                }
            },
            Close: {
                type: Button,
                w: 584,
                h: 96,
                zIndex: 92,
                collision: true,
                label: "close",
                flexItem: { minWidth: 584 },
                signals: {
                    handleClick: "_handleEnter"
                }
            }
        };
    }

    _getFocused() {
        return this.tag("Close");
    }

    _unfocus() {
        this.application.emit("closedLoggedInPopup");
    }

    _handleEnter() {
        Router.focusPage();
    }
}
