import { Lightning, Router, Storage } from "@lightningjs/sdk";
import Button from "../../Button/Button";
import QRCode from "qrcode";
import { SUPPORT_EMAIL } from "../../../lib/utils";
import TranslatableText from "../../TranslatableText/TranslatableText";
import { appPlatformKey, storageGetUser } from "../../../lib/storage";
import { deviceToken } from "../../../lib/api";

export default class SupportPopup extends Lightning.Component {
    static _template() {
        return {
            w: 584,
            collision: true,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            Label: {
                type: TranslatableText,
                key: "supportTitle",
                text: {
                    fontSize: 32
                }
            },
            Content: {
                collision: true,
                w: 584,
                flexItem: {
                    marginTop: 48,
                    grow: 1
                },
                flex: {
                    direction: "row",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                Left: {
                    w: 584,
                    flex: {
                        direction: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    },
                    Label: {
                        type: TranslatableText,
                        key: "contactUs",
                        text: {
                            fontSize: 24,
                            textAlign: "center"
                        }
                    },
                    QrCode: {
                        w: 300,
                        h: 300,
                        flexItem: {
                            marginBottom: 16,
                            marginTop: 16
                        }
                    },
                    Email: {
                        text: {
                            text: SUPPORT_EMAIL,
                            fontSize: 24,
                            textAlign: "center"
                        }
                    }
                }
                // Right: {
                //     flex: {
                //         direction: "column",
                //         alignItems: "flex-start"
                //     },
                //     UserId: {
                //         type: SupportPopupRow,
                //         label: "userId",
                //         value: ""
                //     }
                // }
            },
            Btn: {
                collision: true,
                flexItem: {
                    marginTop: 48
                },
                h: 84,
                w: 584,
                Close: {
                    collision: true,
                    x: 0,
                    type: Button,
                    zIndex: 92,
                    h: 84,
                    w: 584,
                    label: "close",
                    signals: {
                        handleClick: "_handleEnter"
                    }
                }
            }
        };
    }

    _getFocused() {
        return this.tag("Close");
    }

    _handleEnter() {
        Router.focusPage();
    }

    async _init() {
        const platform = Storage.get(appPlatformKey);
        const deviceId = await deviceToken();
        const user = storageGetUser();

        let body = `\n\nPlatform: ${platform} \n`;

        if (deviceId) {
            body += `Device: ${deviceId} \n`;
        }

        if (user) {
            body += `User ID: ${user.id} \n`;
        }

        const qrcode = await QRCode.toDataURL(
            `mailto:${SUPPORT_EMAIL}?${encodeURI(`subject=blackpills&body=${body}`)}`,
            {
                errorCorrectionLevel: "H",
                type: "image/jpeg",
                quality: 1
            }
        );

        this.patch({
            Content: {
                // Right: {
                //     UserId: {
                //         value: user ? user.username : ""
                //     }
                // },
                Left: {
                    QrCode: {
                        src: qrcode
                    }
                }
            }
        });
    }
}
