import { Language, Lightning, Router, Storage } from "@lightningjs/sdk";
import Button from "../../Button/Button";
import { activePosttvSubscription } from "../../../lib/api";
import TranslatableText from "../../TranslatableText/TranslatableText";
import { appPlatformKey } from "../../../lib/storage";
import { PLATFORMS } from "../../../lib/helpers";

export default class SubscriptionPopup extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            w: 584,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            flexItem: { grow: 1 },
            Label: {
                type: TranslatableText,
                key: "hello",
                text: {
                    fontSize: 32
                }
            },
            Info: {
                flexItem: {
                    marginBottom: 48,
                    marginTop: 48
                },
                type: TranslatableText,
                key: "subscriptionRequired",
                text: {
                    fontSize: 24,
                    lineHeight: 36,
                    textAlign: "center",
                    wordWrapWidth: 583,
                    wordWrap: true
                }
            },
            Error: {
                alpha: 1,
                text: {
                    fontSize: 24,
                    lineHeight: 36,
                    textAlign: "center"
                }
            },
            Actions: {
                collision: true,
                w: 584,
                h: 96,
                flex: {
                    direction: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between"
                },
                Cancel: {
                    collision: true,
                    zIndex: 92,
                    type: Button,
                    w: 280,
                    h: 96,
                    x: 0,
                    label: "back",
                    flexItem: { minWidth: 280 },
                    signals: {
                        handleClick: "_setCloseState"
                    }
                },
                Confirm: {
                    collision: true,
                    zIndex: 92,
                    type: Button,
                    w: 280,
                    h: 96,
                    label: "subscribe",
                    flexItem: { minWidth: 280 },
                    signals: {
                        handleClick: "_setConfirmState"
                    }
                }
            }
        };
    }

    _init() {
        const platform = Storage.get(appPlatformKey);
        this.patch({
            Info: {
                key: platform === PLATFORMS.amazon ? "amazonLoginRequired" : "subscriptionRequired"
            }
        });

        if (platform !== PLATFORMS.posttv) {
            this.patch({
                Actions: {
                    Confirm: {
                        alpha: 0
                    },
                    Cancel: {
                        x: 584 / 2 - 280 / 2
                    },
                    flex: {}
                }
            });
        }
    }

    _active() {
        this._setError(null);

        const platform = Storage.get(appPlatformKey);
        if (platform !== PLATFORMS.posttv) {
            this._setState("CloseOnlyState");
        } else {
            this._setState("ConfirmState");
        }
    }

    _setCloseState() {
        this._active();
        this._handleEnter();
    }

    _setConfirmState() {
        this._setState("ConfirmState");
        this._handleEnter();
    }

    _handleUp() {}

    _handleDown() {}

    _handleBack() {
        Router.back();
        Router.focusPage();
    }

    _setError(msg) {
        this.patch({
            Error: {
                text: msg || "",
                alpha: msg ? 1 : 0,
                flexItem: { marginBottom: msg ? 48 : 0 }
            }
        });
    }

    static _states() {
        return [
            class CloseOnlyState extends this {
                _getFocused() {
                    return this.tag("Cancel");
                }

                _handleEnter() {
                    Router.focusPage();
                }
            },
            class CloseState extends this {
                _getFocused() {
                    return this.tag("Cancel");
                }

                _handleEnter() {
                    // Router.back();
                    Router.focusPage();
                }
                _handleRight() {
                    this._setState("ConfirmState");
                }
            },
            class ConfirmState extends this {
                _getFocused() {
                    return this.tag("Confirm");
                }

                async _handleEnter() {
                    this._setError(null);

                    const platform = Storage.get(appPlatformKey);

                    try {
                        if (platform === PLATFORMS.posttv) {
                            const res = activePosttvSubscription();
                            if (res.success) {
                                this.fireAncestors("$postTvSubscribed");
                            } else {
                                this._setError(Language.translate("subscriptionFailed"));
                            }
                        }
                    } catch (e) {
                        this._setError(Language.translate("unexpectedError"));
                    }
                }

                _handleLeft() {
                    this._setState("CloseState");
                }
            }
        ];
    }
}
