import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import { BackButton } from "../index";
import Button from "../Button/Button";
import { getAudioLanguageName, getLanguageName } from "../../lib/helpers";
import { storageGetCurrentShow } from "../../lib/storage";

export default class PlayerTop extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            collision: true,
            color: Colors("black")
                .alpha(0.5)
                .get(),
            y: 0,
            x: 0,
            h: 108,
            w: 1920,
            flex: { direction: "row", alignItems: "center", justifyContent: "space-between" },
            Back: {
                type: BackButton,
                flexItem: { margin: 52 },
                h: 36,
                w: 100,
                zIndex: 92,
                signals: {
                    goBack: "_triggerBack"
                }
            },
            Language: {
                collision: true,
                h: 52,
                flex: { grow: 1, direction: "row", justifyContent: "center" },
                Audio: {
                    type: Button,
                    w: 260,
                    h: 52,
                    zIndex: 92,
                    prevIcon: "icons/audio.png",
                    activeIcon: "icons/arrow-active.png",
                    useBorder: false,
                    alpha: 0.01,
                    signals: {
                        handleClick: "_handleAudioClick"
                    }
                },
                Subtitles: {
                    type: Button,
                    w: 260,
                    h: 52,
                    zIndex: 92,
                    prevIcon: "icons/subs.png",
                    activeIcon: "icons/arrow-active.png",
                    useBorder: false,
                    alpha: 0.01,
                    signals: {
                        handleClick: "_handleSubClick"
                    }
                }
            },
            Logo: {
                h: 37,
                w: 100,
                flexItem: { margin: 52 },
                flex: { alignItems: "center", justifyContent: "flex-end" },
                Img: {
                    src: Utils.asset("images/logo-sm.png"),
                    w: 40,
                    h: 37
                }
            }
        };
    }

    static get h() {
        return 108;
    }

    set selectedSub(value) {
        this._hasSubs = true;
        if (value === "off") {
            this.patch({
                Language: {
                    Subtitles: {
                        flexItem: { minWidth: 260, minHeight: 52 },
                        label: "off",
                        alpha: 1
                    }
                }
            });
        } else {
            this.patch({
                Language: {
                    Subtitles: {
                        flexItem: { minWidth: 260, minHeight: 52 },
                        text: getLanguageName(value),
                        alpha: 1
                    }
                }
            });
        }
    }

    set selectedAudio(value) {
        this._hasAudio = true;
        this.patch({
            Language: {
                Audio: {
                    flexItem: { minWidth: 260, minHeight: 52 },
                    text: getAudioLanguageName(value),
                    alpha: 1
                }
            }
        });
    }

    reset() {
        this._setState("BackState");
    }

    _getFocused() {
        this._setState("BackState");
        return this.tag("Back");
    }

    _active() {
        const show = storageGetCurrentShow();
        if (show) {
            this.patch({
                Back: {
                    label: show.title
                }
            });
        }
    }

    _triggerBack() {
        this.signal("triggerBack");
    }

    _handleAudioClick() {
        this._setState("AudioState");
        this._handleEnter();
    }

    _handleSubClick() {
        this._setState("SubsState");
        this._handleEnter();
    }

    static _states() {
        return [
            class BackState extends this {
                _handleEnter() {
                    this._triggerBack();
                }

                _handleRight() {
                    if (this._hasAudio) {
                        this._setState("AudioState");
                    } else if (this._hasSubs) {
                        this._setState("SubsState");
                    }
                }
            },
            class AudioState extends this {
                _getFocused() {
                    return this.tag("Audio");
                }

                _handleLeft() {
                    this._setState("BackState");
                }

                _handleRight() {
                    if (this._hasSubs) {
                        this._setState("SubsState");
                    }
                }

                _handleEnter() {
                    this.fireAncestors("$onAudioSelect");
                }
            },
            class SubsState extends this {
                _getFocused() {
                    return this.tag("Subtitles");
                }

                _handleLeft() {
                    if (this._hasAudio) {
                        this._setState("AudioState");
                    } else {
                        this._setState("BackState");
                    }
                }

                _handleEnter() {
                    this.fireAncestors("$onSubsSelect");
                }
            }
        ];
    }
}
