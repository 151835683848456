import { Language, Lightning } from "@lightningjs/sdk";

export default class TranslatableText extends Lightning.Component {
    static _template() {
        return {};
    }

    set key(value) {
        this._key = value;

        this._translate();
    }

    set translate(obj) {
        this._obj = obj;

        this._translate();
    }

    _init() {
        this.listeners = {
            appLanguageChanged: () => {
                this._translate();
            }
        };
    }

    _attach() {
        ["appLanguageChanged"].forEach(event => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detach() {
        ["appLanguageChanged"].forEach(event => {
            this.application.off(event, this.listeners[event]);
        });
    }

    _translate() {
        this.patch({
            text: {
                text: this._key
                    ? Language.translate(this._key)
                    : this._obj
                    ? Language.translate(
                          this._obj.key,
                          typeof this._obj.value !== "object" ? { value: this._obj.value } : this._obj.value
                      )
                    : ""
            }
        });
    }
}
