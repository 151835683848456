import { Lightning, Router } from "@lightningjs/sdk";
import Button from "../../Button/Button";
import { getSubscriptionLink } from "../../../lib/api";
import TranslatableText from "../../TranslatableText/TranslatableText";
import QRCode from "qrcode";
import { TERMS_URL } from "../../../lib/utils";

export default class LoginPopup extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            zIndex: 92,
            w: 584,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            flexItem: { grow: 1 },
            Label: {
                type: TranslatableText,
                key: "register",
                text: {
                    fontSize: 32
                }
            },
            InfoPrice: {
                flexItem: {
                    marginTop: 16
                },
                type: TranslatableText,
                key: "subscriptionPrice",
                text: {
                    fontSize: 24,
                    lineHeight: 36,
                    textAlign: "center",
                    wordWrapWidth: 583,
                    wordWrap: true
                }
            },
            Info: {
                flexItem: {
                    marginBottom: 16,
                    marginTop: 16
                },
                type: TranslatableText,
                key: "registerInfo",
                text: {
                    fontSize: 24,
                    lineHeight: 36,
                    textAlign: "center",
                    wordWrapWidth: 583,
                    wordWrap: true
                }
            },
            QrCode: { w: 300, h: 300 },
            Link: {
                flexItem: { marginTop: 24, marginBottom: 48 },
                text: {
                    fontSize: 24,
                    fontStyle: 500
                }
            },
            Terms: {
                flexItem: {
                    marginBottom: 12
                },
                type: TranslatableText,
                key: "agreementTitle",
                text: {
                    fontSize: 24,
                    lineHeight: 36,
                    textAlign: "center",
                    wordWrapWidth: 583,
                    wordWrap: true
                }
            },
            TermsLink: {
                flexItem: {
                    marginBottom: 48
                },
                text: {
                    text: TERMS_URL,
                    fontSize: 24,
                    lineHeight: 36,
                    textAlign: "center",
                    wordWrapWidth: 583,
                    wordWrap: true
                }
            },
            Close: {
                collision: true,
                zIndex: 92,
                type: Button,
                w: 584,
                h: 96,
                label: "close",
                flexItem: { minWidth: 584 },
                signals: {
                    handleClick: "_handleEnter"
                }
            }
        };
    }

    set signupLink(value) {
        this._signupLink = value;
    }

    _getFocused() {
        return this.tag("Close");
    }

    async _active() {
        try {
            const res = await getSubscriptionLink();
            this._signupLink = res.url;
        } catch (e) {
            // TODO: show error

            return;
        }

        this.fireAncestors("$checkLogin");

        const qrcode = await QRCode.toDataURL(this._signupLink, {
            errorCorrectionLevel: "H",
            type: "image/jpeg",
            quality: 1
        });
        this.patch({
            QrCode: {
                src: qrcode
            },
            Link: {
                text: {
                    text: this._signupLink
                }
            }
        });
    }

    _handleEnter() {
        this.application.emit("loginClosed");

        Router.focusPage();
    }
}
