import { Language, Router, Storage } from "@lightningjs/sdk";
import {
    appPlatformKey,
    storageAppStartTimeKey,
    storageGetUser,
    storageSaveUser,
    storageSetCurrentShow
} from "./storage";

import posthog from "posthog-js";
import { getSubscription } from "./api";

export const PLATFORMS = {
    posttv: "posttv",
    samsung: "samsung",
    lg: "lg",
    amazon: "amazon",
    zeasn: "zeasn",
    google: "google"
};

export const handleMovieSelect = async (widgets, { data, parent, continueWatching }) => {
    if (data.isSeason === 1) {
        Router.navigate(`series/${data.movieId}`);
    } else {
        let user = storageGetUser();

        const isPosttv = Storage.get(appPlatformKey) === PLATFORMS.posttv;

        if (!isPosttv && user) {
            try {
                const updatedUserInfo = await getSubscription();
                if (updatedUserInfo.success) {
                    storageSaveUser(updatedUserInfo);
                    user = storageGetUser();
                }
            } catch (e) {
                console.log("e", e);
            }
        }

        const appData = Storage.get("app");
        const isEpFree = data.episodeNumber <= appData?.freeEpisodes;

        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: `isEpFree ${isEpFree}, data.episodeNumber ${data.episodeNumber}`
            })
        );

        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: `!isEpFree && Storage.get(appPlatformKey) === PLATFORMS.amazon && (!user || (user && !user.userId) ${
                    !isEpFree && Storage.get(appPlatformKey) === PLATFORMS.amazon && (!user || (user && !user.userId))
                }`
            })
        );

        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: `!isEpFree && ((!isPosttv && !user) || (!isPosttv && user && !user.canWatch)) ${
                    !isEpFree && ((!isPosttv && !user) || (!isPosttv && user && !user.canWatch))
                }`
            })
        );

        if (!isEpFree && Storage.get(appPlatformKey) === PLATFORMS.amazon && (!user || (user && !user.userId))) {
            window.dispatchEvent(new CustomEvent("triggerLoginRequired", {}));
        } else if (!isEpFree && ((!isPosttv && !user) || (!isPosttv && user && !user.canWatch))) {
            widgets.authpopup && widgets.authpopup.open();
        } else {
            let movie = parent || (continueWatching ? data.parent || data : data);
            if (movie.movie) {
                movie = movie.movie;
            }

            storageSetCurrentShow({
                continueWatching: continueWatching || false,
                id: movie.movieId,
                title: movie.title,
                episodes: movie.episodes
                    ? movie.episodes.map((ep) => {
                          return {
                              id: ep.movieId,
                              episodeNumber: ep.episodeNumber
                          };
                      })
                    : []
            });

            Router.navigate(`playback/${data.movieId}`);
        }
    }
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLanguageName = (code) => {
    return capitalizeFirstLetter(code);
};

export const getCategoryNamesTranslated = (categories) => {
    const currentLang = Language.get();
    return categories
        .map((cat) => {
            const translated = cat.translations.find((tr) => tr.language === currentLang);
            return translated ? translated.name : cat.name;
        })
        .join(", ");
};

export const getMetadataTranslated = (movie) => {
    const currentLang = Language.get();

    return (
        movie.metadata[currentLang] || {
            title: movie.title,
            description: movie.description
        }
    );
};

export const getMovieTitle = (movie) => {
    if (movie.title.indexOf(" - Season") !== -1) {
        movie.title = movie.title.split(" - Season")[0];
    }

    return movie.title;
};

export const onAppExit = () => {
    posthog.capture("AppExit", {
        usage_time_ms: new Date().getTime() - +Storage.get(storageAppStartTimeKey)
    });
};

export const getAudioLanguageName = (code) => {
    const lang = Storage.get("audioLanguages").find((l) => l.code === code);
    return lang ? capitalizeFirstLetter(lang.name) : code;
};
