import { Lightning, Router } from "@lightningjs/sdk";
import SettingsItem from "./components/SettingsItem";
import LogoutPopup from "./components/LogoutPopup";

export default class Auth extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            Item: {
                collision: true,
                type: SettingsItem,
                label: "",
                icon: "icons/user.png"
            }
        };
    }

    set loggedIn(value) {
        this._loggedIn = value;

        this.patch({
            Item: {
                label: value ? "logout" : "login"
            }
        });
    }

    set username(value) {
        this._username = value ? value.split(" ").shift() : "";

        this.patch({
            Item: {
                username: this._username
            }
        });
    }

    set widgets(value) {
        this._widgets = value;
    }

    _getFocused() {
        return this.tag("Item");
    }

    _init() {
        this.patch({
            Item: {
                w: this.w,
                h: this.h
            }
        });
    }

    async _handleEnter() {
        if (!this._loggedIn) {
            this._widgets.authpopup.open();
            Router.focusWidget("AuthPopup");
        } else {
            this._widgets.modal.open([
                {
                    type: LogoutPopup,
                    username: this._username
                }
            ]);
            Router.focusWidget("Modal");
        }
    }
}
