import { storageGetUser } from "./storage";
import posthog from "posthog-js";

export const POSTER_VERTICAL_DIMENSIONS = {
    m: {
        h: 850,
        w: 475,
        fH: 930,
        fW: 520,
        lH: 93
    },
    equalM: {
        h: 930,
        w: 520,
        fH: 930,
        fW: 520,
        lH: 93,
        lF: 24
    }
};

export const POSTER_HORIZONTAL_DIMENSIONS = {
    s: {
        h: 333,
        w: 186,
        fH: 333,
        fW: 596,
        lH: 62
    },
    m: { h: 400, w: 223, fH: 400, fW: 596, lH: 93 },
    l: { h: 400, w: 223, fH: 500, fW: 895, lH: 93 },
    xl: { h: 500, w: 279, fH: 500, fW: 895, lH: 93 },
    xxl: { h: 560, w: 312, fH: 560, fW: 1002, lH: 98 },
    equalXxl: { h: 560, w: 360, fH: 560, fW: 360, lH: 70, lF: 21 },
    equalL: { h: 460, w: 300, fH: 460, fW: 300, lH: 56, lF: 21 },
    equalS: {
        h: 333,
        w: 200,
        fH: 333,
        fW: 200,
        lH: 40,
        lF: 13
    }
};

export const MENU_PAGE_IDS = {
    settings: "settings",
    home: "home",
    search: "search",
    favorites: "favorites",
    browse: "browse",
    exit: "exit"
};

export const SUPPORT_EMAIL = "hello@alteox.support";
export const SUBSCRIPTION_URL = "pay.alteox.com";
export const TERMS_URL = "https://pay.alteox.com/blackpills-legal";

export const pageTransition = (pageIn) => {
    return new Promise((resolve) => {
        // set the start position properties
        pageIn.alpha = 0;

        // toggle visibility
        pageIn.visible = true;

        // do some transitions
        pageIn.patch({
            // smooth: { alpha: 1 }
            smooth: { alpha: [1, { duration: 0.4, timingFunction: "linear" }] }
        });

        // resolve Promise when transition on x is finished
        pageIn.transition("alpha").on("finish", () => {
            resolve();
        });
    });
};

export const KEYS = {
    enter: 13,
    top: 38,
    bottom: 40,
    left: 37,
    right: 39,
    back: 461
};

export const BACK_KEYS = [
    "Backspace",
    "Escape",
    "Back",
    "XF86Back",
    8,
    461,
    10009,
    10182,
    "GoBack",
    "Unidentified",
    18874371
];

export const posthogCaptureAppStart = () => {
    const user = storageGetUser();
    posthog.capture("AppStart", {
        user_logged_in: !!user,
        user_subscribed: user?.canWatch
    });
};
