import Hls from "hls.js";
import {
    appPlatformKey,
    storageGetPreferredAudio,
    storageGetPreferredSubtitles,
    storageSetPreferredAudio,
    storageSetPreferredSubtitles
} from "./storage";
import { translateLanguages, urlPath } from "./api";
import { Storage } from "@lightningjs/sdk";
import { PLATFORMS } from "./helpers";
import dashjs from "dashjs";

export default class VideoPlayedExtended {
    constructor() {
        this.listeners = new Map();

        this._hls = null;
        this._dash = null;
        this._playbackData = null;
        this._videoEl = null;
    }

    get hls() {
        return this._hls;
    }

    on(label, callback) {
        this.listeners.has(label) || this.listeners.set(label, []);
        this.listeners.get(label).push(callback);
    }

    create(url, videoEl, playbackData) {
        this._unload(videoEl);

        this._doAutoplay = false;
        this._playbackData = playbackData;
        this._videoEl = videoEl;

        if (Storage.get(appPlatformKey) !== PLATFORMS.zeasn) {
            this.createSubs();
        }

        const isStream = url.split(".").pop() === "m3u8";

        const _player = this;

        if (this._playbackData.format === "dash") {
            this._dash = dashjs.MediaPlayer().create();

            if (this._playbackData.drm === "widevine") {
                this._dash.setProtectionData({
                    "com.widevine.alpha": {
                        serverURL: this._playbackData.tokenUrl
                    }
                });
            } else {
                this._dash.setProtectionData({
                    "com.microsoft.playready": {
                        serverURL: this._playbackData.tokenUrl
                    }
                });
            }

            this._dash.initialize(videoEl);

            this._dash.attachSource(url);
            this._dash.on(dashjs.MediaPlayer.events.STREAM_INITIALIZED, () => {
                _player.createAudio(_player._dash.getTracksFor("audio"));
            });
        } else if (Hls.isSupported() && isStream) {
            this._hls = new Hls({
                debug: false,
                forceKeyFrameOnDiscontinuity: false,
                enableWorker: true,
                lowLatencyMode: true,
                backBufferLength: 20,
                maxBufferLength: 10,
                maxMaxBufferLength: 15,
                maxBufferSize: 60
            });
            const hls = this._hls;

            // bind them together
            hls.attachMedia(videoEl);

            hls.streamController.flushMainBuffer(0, 0);

            // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                console.log("video and hls.js are now bound together !");
                hls.loadSource(url);

                if (_player._doAutoplay) {
                    _player._videoEl.play();
                }
            });

            hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, function () {
                console.log("Hls.Events.AUDIO_TRACKS_UPDATED", Hls.Events.AUDIO_TRACKS_UPDATED);
                _player.createAudio(hls.audioTracks);
            });

            hls.on(Hls.Events.ERROR, function (event, data) {
                console.log("ERROR", event, data);

                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            // try to recover network error
                            console.log("fatal network error encountered, try to recover");
                            _player._doAutoplay = true;

                            hls.startLoad();
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            console.log("fatal media error encountered, try to recover");
                            _player._doAutoplay = true;

                            hls.recoverMediaError();
                            break;
                        default:
                            // cannot recover
                            hls.destroy();
                            break;
                    }
                }
            });

            hls.on(Hls.Events.FRAG_CHANGED, function () {
                const now = Date.now();
                if (_player.lastFlushed && now - _player.lastFlushed >= 10000) {
                    hls.streamController.flushMainBuffer(0, hls.media.currentTime - 10);
                    _player.lastFlushed = now;
                }
                // hls.trigger(Event.BUFFER_FLUSHING, { startOffset: 0, endOffset: _player._videoEl.currentTime - 15 });
            });
        } else {
            const source = document.createElement("source");
            source.setAttribute("src", url);

            if (isStream) {
                source.setAttribute("type", "application/x-mpegURL");
            }

            videoEl.appendChild(source);
            videoEl.load();
        }
    }

    createSubs() {
        let selectedLang = "off";

        this._videoEl.setAttribute("crossorigin", "crossorigin");

        const defaultSubs = storageGetPreferredSubtitles();
        for (const sub of this._playbackData.subtitles) {
            const track = document.createElement("track");
            track.setAttribute("kind", "subtitles");
            track.setAttribute("label", sub.language);
            track.setAttribute("srcLang", sub.languageCode);
            track.setAttribute("src", urlPath() + "/misc/vtt?url=" + sub.link);
            // track.setAttribute("src", "https://blackpills-proxy.alteox.app/api/misc/vtt?url=" + sub.link);

            if (sub.languageCode === defaultSubs) {
                selectedLang = defaultSubs;
            }

            this._videoEl.appendChild(track);
        }

        this.selectSubs(selectedLang);

        if (this.listeners.get("selectedSubs")) {
            for (const listener of this.listeners.get("selectedSubs")) {
                listener(selectedLang);
            }
        }
    }

    selectSubs(langCode) {
        // langCode can be also "off", will disable all subs
        for (const track of this._videoEl.textTracks) {
            if (track.language === langCode) {
                track.mode = "showing";
            } else {
                track.mode = "hidden";
            }
        }

        storageSetPreferredSubtitles(langCode);
    }

    createAudio(tracks) {
        let selectedAudio = null;
        const defaultAudio = storageGetPreferredAudio() || "en";

        translateLanguages(
            tracks.map((track) => {
                return {
                    code: track.lang
                };
            })
        ).then((languages) => {
            Storage.set("audioLanguages", languages);

            for (const track of tracks) {
                if (track.lang === defaultAudio) {
                    selectedAudio = track.lang;

                    if (!track.default) {
                        this.selectAudioByTrack(track);
                    }
                } else if (track.default && !selectedAudio) {
                    selectedAudio = track.lang;
                }
            }

            if (!selectedAudio && tracks.length) {
                selectedAudio = tracks[0].lang;
            }

            if (this.listeners.get("audioTracksUpdated")) {
                for (const listener of this.listeners.get("audioTracksUpdated")) {
                    listener(tracks);
                }
            }

            if (this.listeners.get("selectedAudio")) {
                for (const listener of this.listeners.get("selectedAudio")) {
                    listener(selectedAudio);
                }
            }
        });
    }

    selectAudioByTrack(track) {
        if (this._hls) {
            this._hls.audioTrack = track.id;
        } else {
            this._dash.setCurrentTrack(track);
        }
    }

    selectAudio(langCode) {
        let selected;
        if (this._dash) {
            selected = this._dash.getTracksFor("audio").find((track) => track.lang === langCode);
        } else if (this._hls) {
            selected = this.hls.audioTracks.find((track) => track.lang === langCode);
        }

        if (selected) {
            this.selectAudioByTrack(selected);

            storageSetPreferredAudio(langCode);
        }
    }

    destroy(videoEl) {
        this._unload(videoEl || this._videoEl);

        this.listeners = new Map();
        this._playbackData = null;
    }

    _unload(videoEl) {
        if (this._hls && this._hls.destroy) {
            this._hls.destroy();
        } else if (this._dash && this._dash.destroy) {
            this._dash.destroy();
        }

        if (videoEl) {
            for (const track of videoEl.textTracks) {
                track.mode = "hidden";
            }

            videoEl.innerHTML = "";

            videoEl.removeAttribute("src");
            videoEl.load();
        }

        this._hls = null;
        this._dash = null;
        this._videoEl = null;
    }
}
