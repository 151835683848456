import IconKey, { KEY_DIMENSIONS } from "./IconKey";
import { Lightning } from "@lightningjs/sdk";
import { Column, Row } from "@lightningjs/ui-components";

export default class Keyboard extends Lightning.Component {
    _construct() {
        this._whenEnabled = new Promise((resolve) => (this._firstEnable = resolve));
    }

    get announce() {
        return "Keyboard" + (this.title ? `, ${this.title}` : "");
    }

    get announceContext() {
        return ["PAUSE-2", "Use arrow keys to choose characters, press center to select"];
    }

    set rowWrap(rowWrap) {
        this._rowWrap = rowWrap;
    }

    set formats(formats) {
        this._formats = formats;
        this._currentFormat = this._defaultFormat;
        // Ensure formats prop is set last
        this._whenEnabled.then(() => {
            Object.entries(formats).forEach(([key, value]) => {
                const keyboardData = this._formatKeyboardData(value);
                this._createKeyboard(key, this._createRows(keyboardData));
            });
            this.tag(this._currentFormat).alpha = 1;
            this._refocus();
        });
    }

    _createKeyboard(key, rows = []) {
        key = key.charAt(0).toUpperCase() + key.slice(1);
        if (rows.length === 1) {
            this.patch({ [key]: { ...rows[0], alpha: 0 } });
        } else {
            this.patch({
                [key]: {
                    type: Column,
                    alpha: 0,
                    plinko: true,
                    itemSpacing: this._spacing,
                    items: rows,
                    collision: true
                }
            });
        }
    }

    _createRows(rows = []) {
        return rows.map((keys, index) => {
            const h = (this.keysConfig && this.keysConfig.h) || KEY_DIMENSIONS.h;
            return {
                type: Row,
                h,
                w: KEY_DIMENSIONS.w * 5 + 10,
                wrapSelected: this._rowWrap === undefined ? true : this._rowWrap,
                itemSpacing: 1,
                items: this._createKeys(keys, index),
                collision: true
            };
        });
    }

    _createKeys(keys = [], rowIndex) {
        return keys.map((keyProps, index) => {
            const key = {
                type: this.keyComponent || IconKey,
                config: this.keysConfig,
                row: rowIndex,
                column: index
            };
            if (!keyProps) {
                return { ...KEY_DIMENSIONS, skipFocus: true };
            } else if (typeof keyProps === "object") {
                return { ...key, ...keyProps };
            }
            return { ...key, label: keyProps };
        });
    }

    _formatKeyboardData(data = []) {
        if (Array.isArray(data) && data.length) {
            if (!Array.isArray(data[0]) && !this.inline) {
                const keyRows = [];
                let idx, counter;
                for (idx = 0, counter = -1; idx < data.length; idx++) {
                    if (idx % this.columnCount === 0) {
                        counter++;
                        keyRows[counter] = [];
                    }
                    keyRows[counter].push(data[idx]);
                }
                return keyRows;
            } else if (this.inline) {
                return [data];
            }
            return data;
        }
    }

    $toggleKeyboard(keyboardFormat) {
        keyboardFormat = keyboardFormat.charAt(0).toUpperCase() + keyboardFormat.slice(1);
        if (keyboardFormat !== this._currentFormat) {
            // this.selectKeyOn(this.tag(keyboardFormat));
            this.tag(this._currentFormat).alpha = 0;
            this.tag(keyboardFormat).alpha = 1;
            this._currentFormat = keyboardFormat;
        }
    }

    $setKeyboard(data) {
        this.setKey(data);
    }

    setKey({ row, column }) {
        const keyboard = this.tag(this._currentFormat);

        keyboard.selectedIndex = row;
        keyboard._Items.children[row].selectedIndex = column;
    }

    selectKeyOn(keyboard, { column } = this.getSelectedKey()) {
        const type = keyboard.constructor.name;
        if (type === "Row") {
            keyboard.selectedIndex = column;
        } else {
            keyboard.selectedIndex = 0;
            keyboard._Items.children[0].selectedIndex = 0;
        }
    }

    getSelectedKey() {
        let row, column;
        const keyboard = this.tag(this._currentFormat);
        const type = keyboard.constructor.name;
        if (type === "Row") {
            row = 0;
            column = keyboard.selectedIndex;
        } else {
            row = keyboard.selectedIndex;
            column = keyboard._Items.children[row].selectedIndex;
        }
        return { row, column };
    }

    _getFocused() {
        return this.tag(this._currentFormat) || this;
    }

    _focus() {
        this.fireAncestors("$keyboardFocused", true);
    }

    _unfocus() {
        this.fireAncestors("$keyboardFocused", false);
    }

    set columnCount(columnCount) {
        this._columnCount = columnCount;
    }

    set rowCount(rowCount) {
        this._rowCount = rowCount;
    }

    get columnCount() {
        if (this._columnCount) return this._columnCount;
        if (this._rowCount) return this._formats[this._defaultFormat.toLowerCase()].length / this._rowCount;
        if (this.inline) return this._formats[this._defaultFormat.toLowerCase()].length;
        else return 11;
    }

    get _spacing() {
        return this.spacing || 8;
    }

    get _defaultFormat() {
        const defaultFormat = this.defaultFormat || Object.keys(this._formats)[0];
        return defaultFormat.charAt(0).toUpperCase() + defaultFormat.slice(1);
    }
}

export const KEYBOARD_FORMATS = {
    qwerty: [
        ["a", "b", "c", "d", "e"],
        ["f", "g", "h", "i", "j"],
        ["k", "l", "m", "n", "o"],
        ["p", "q", "r", "s", "t"],
        ["u", "v", "w", "x", "y"],
        [
            "z",
            "",
            {
                icon: "icons/delete.png",
                char: "delete"
            },
            {
                icon: "icons/space.png",
                char: "space"
            },
            {
                label: "123",
                toggle: "num"
            }
        ]
    ],
    num: [
        ["1", "2", "3", "4", "5"],
        ["6", "7", "8", "9", "0"],
        [
            "",
            {
                icon: "icons/delete.png",
                char: "delete"
            },
            {
                icon: "icons/space.png",
                char: "space"
            },
            {
                label: "abc",
                toggle: "qwerty"
            },
            ""
        ]
    ],
    numOnly: [
        ["1", "2", "3", "4", "5"],
        ["6", "7", "8", "9", "0"],
        [
            "",
            "",
            {
                icon: "icons/delete.png",
                char: "delete"
            }
        ]
    ]
};
