if (typeof window.tizen !== "undefined" && window.tizen.tvinputdevice && window.tizen.tvinputdevice.registerKey) {
    try {
        window.tizen.tvinputdevice.registerKey("MediaPlayPause"); // allow MediaPlayPause button
        window.tizen.tvinputdevice.registerKey("MediaRewind"); // allow MediaRewind button
        window.tizen.tvinputdevice.registerKey("MediaFastForward"); // allow MediaFastForward button
        window.tizen.tvinputdevice.registerKey("MediaPlay"); // allow MediaPlay button
        window.tizen.tvinputdevice.registerKey("MediaPause"); // allow MediaPause button
        window.tizen.tvinputdevice.registerKey("MediaStop"); // allow MediaStop button
    } catch (e) {
        console.log("register error", e);
    }
}
