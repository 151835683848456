import { Lightning } from "@lightningjs/sdk";
import { getCategoryNamesTranslated } from "../../lib/helpers";

export default class MovieDetailMeta extends Lightning.Component {
    static _template() {
        return {
            w: 454,
            flexItem: { maxWidth: 400, wrap: true },
            flex: { direction: "row", justifyContent: "center" }
        };
    }

    set data(value) {
        this._data = value;

        this.patch({
            Category: {
                text: {
                    text: getCategoryNamesTranslated(value.categories),
                    fontSize: 24,
                    wordWrapWidth: 320,
                    wordWrap: true,
                    textAlign: "center",
                    fontStyle: 500
                },
                flexItem: { marginRight: 32, maxWidth: 320 }
            },
            Year: {
                flexItem: {},
                text: {
                    text: `(${value.productionYear})`,
                    fontSize: 24,
                    wordWrapWidth: 300,
                    wordWrap: true,
                    textAlign: "center",
                    fontStyle: 400
                }
            }
        });
    }
}
