import { Colors, Lightning, Router, Utils, Storage } from "@lightningjs/sdk";
import { MENU_PAGE_IDS, pageTransition, POSTER_VERTICAL_DIMENSIONS } from "../lib/utils";
import { handleMovieSelect, PLATFORMS } from "../lib/helpers";
import TranslatableText from "../components/TranslatableText/TranslatableText";
import { MovieList } from "../components";
import { appPlatformKey } from "../lib/storage";

export default class Favorites extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            w: 1920,
            h: 1080,
            color: Colors("pageBackground").get(),
            Page: {
                x: 112,
                w: 1808,
                h: 1080,
                Title: {
                    y: 32,
                    type: TranslatableText,
                    key: "favorites",
                    text: {
                        fontStyle: 500,
                        fontSize: 24
                    }
                },
                List: {
                    y: 80,
                    itemSpacing: 16,
                    type: MovieList
                },
                Empty: {
                    alpha: 0.01,
                    ImgBox: {
                        w: 1808,
                        h: 1080,
                        flex: { alignItems: "center", justifyContent: "center" },
                        Img: {
                            w: 1048,
                            h: 1048,
                            src: Utils.asset("icons/favorite-lg.png")
                        }
                    },
                    TextBox: {
                        w: 1808,
                        h: 1080,
                        flex: { alignItems: "center", justifyContent: "center" },
                        Text: {
                            y: -26,
                            type: TranslatableText,
                            key: "favoritesEmpty",
                            text: {
                                wordWrapWidth: 320,
                                fontStyle: 500,
                                fontSize: 24,
                                lineHeight: 36,
                                textAlign: "center"
                            }
                        }
                    }
                }
            }
        };
    }

    pageTransition(pageIn) {
        return pageTransition(pageIn);
    }

    set data(data) {
        this._data = data;

        this._renderData();
    }

    _getFocused() {
        return this.tag("List");
    }

    _active() {
        this.widgets.menu.setActive(MENU_PAGE_IDS.favorites);
    }

    _handleLeft() {
        Router.focusWidget("Menu");
    }

    _handleBack() {
        Router.navigate("home");
    }

    _renderData() {
        const isEmpty = this._data.length === 0;
        this.patch({
            Page: {
                Title: {
                    alpha: isEmpty ? 0 : 1
                },
                List: {
                    alpha: isEmpty ? 0 : 1,
                    dimensions:
                        Storage.get(appPlatformKey) === PLATFORMS.posttv
                            ? POSTER_VERTICAL_DIMENSIONS.equalM
                            : POSTER_VERTICAL_DIMENSIONS.m,
                    vertical: true,
                    data: this._data.map(s => s.movie)
                },
                Empty: {
                    alpha: isEmpty ? 1 : 0
                }
            }
        });
    }

    $onMovieSelect(event) {
        handleMovieSelect(this.widgets, event);
    }
}
