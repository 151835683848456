import { Colors, Lightning, Storage } from "@lightningjs/sdk";
import { POSTER_HORIZONTAL_DIMENSIONS } from "../../lib/utils";
import { MovieList } from "../index";
import { appPlatformKey } from "../../lib/storage";
import { PLATFORMS } from "../../lib/helpers";

export default class CategoryList extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            rect: true,
            color: "transparent",
            h: 542,
            Label: {
                y: 0,
                x: 0,
                h: 36,
                text: {
                    fontSize: 24,
                    fontStyle: 500,
                    textColor: Colors("white").get()
                }
            },
            CategoryList: {
                collision: true,
                y: 42,
                w: 1808,
                type: MovieList
            }
        };
    }

    set selectedIndex(value) {
        this._selectedIndex = value;
    }

    set category(data) {
        this._data = data;
    }

    set index(value) {
        this._index = value;
    }

    _getFocused() {
        return this.tag("CategoryList");
    }

    _init() {
        this.tag("Label").text = this._data.nameDisplay;

        this.patch({
            CategoryList: {
                selectedIndex: this._data.selectedIndex || 0,
                dimensions:
                    Storage.get(appPlatformKey) === PLATFORMS.posttv
                        ? POSTER_HORIZONTAL_DIMENSIONS.equalL
                        : POSTER_HORIZONTAL_DIMENSIONS.l,
                data: this._data.series.map(show => {
                    show.category = this._data;
                    return show;
                })
            }
        });
    }

    _active() {}

    $movieClicked() {
        if (!this.hasFocus()) {
            this.parent.parent.selectedIndex = this._index;
        }
    }

    _focus() {
        this.patch({
            h: Storage.get(appPlatformKey) === PLATFORMS.posttv ? 510 : 542
        });

        this.application.emit("categoryFocus", this._data);
    }

    _unfocus() {
        this.application.emit("categoryBlur", this._data);
    }
}
