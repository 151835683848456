import { Language, Lightning, Router } from "@lightningjs/sdk";
import LanguageSelectorItem from "./LanguageSelectorItem";
import { Carousel } from "@lightningjs/ui";
import Button from "../../../Button/Button";
import { storageSetLanguage } from "../../../../lib/storage";
import TranslatableText from "../../../TranslatableText/TranslatableText";

export default class LanguageSelector extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            w: 584,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            flexItem: { grow: 1 },
            Label: {
                h: 80,
                type: TranslatableText,
                key: "language",
                text: {
                    fontSize: 32
                }
            },
            Languages: {
                type: Carousel,
                w: 584,
                collision: true,
                clipping: true,
                direction: "column"
            },
            Actions: {
                collision: true,
                w: 584,
                h: 96,
                flexItem: {
                    marginTop: 48
                },
                flex: {
                    direction: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between"
                },
                Back: {
                    collision: true,
                    zIndex: 92,
                    type: Button,
                    w: 280,
                    h: 96,
                    x: 0,
                    label: "back",
                    flexItem: { minWidth: 280 },
                    signals: {
                        handleClick: "_setBackState"
                    }
                },
                Done: {
                    collision: true,
                    zIndex: 92,
                    type: Button,
                    w: 280,
                    h: 96,
                    label: "done",
                    flexItem: { minWidth: 280 },
                    signals: {
                        handleClick: "_setDoneState"
                    }
                }
            }
        };
    }
    _calcHeight(total) {
        return this._itemHeight * total + this._itemSpacing * total - 1;
    }

    _construct() {
        this._itemHeight = 60;
        this._itemSpacing = 28;

        this._maxHeight = this._calcHeight(6);
    }

    _getFocused() {
        return this.tag("Languages");
    }

    _handleUp() {}

    _setBackState() {
        this._setState("BackState");
        this._handleEnter();
    }

    _setDoneState() {
        this._setState("DoneState");
        this._handleEnter();
    }

    _init() {
        this._setState("SelectorState");

        this._languages = Language.available();

        this.patch({
            Languages: {
                h: Math.min(this._calcHeight(this._languages.length), this._maxHeight),
                spacing: this._itemSpacing
            }
        });

        this.tag("Languages").add(
            this._languages.map(lang => {
                return {
                    type: LanguageSelectorItem,
                    w: 584,
                    h: 60,
                    lang
                };
            })
        );

        this.tag("Languages").setIndex(this._languages.findIndex(lang => lang.code === Language.get()));

        for (const wrapper of this.tag("Languages").children) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    _getSelectedLangCode() {
        const item = this.tag("Languages").currentItem;
        return item.lang.code;
    }

    $languageSelected() {
        this._setState("SelectorState");
    }

    static _states() {
        return [
            class SelectorState extends this {
                _getFocused() {
                    return this.tag("Languages");
                }

                _handleBack() {
                    this._selectLanguage();
                }

                _handleEnter() {
                    this._selectLanguage();
                }

                _selectLanguage() {
                    this._setState("DoneState");

                    this.application.emit("languageSelected", this._getSelectedLangCode());
                }
            },
            class BackState extends this {
                _getFocused() {
                    return this.tag("Back");
                }

                _handleUp() {
                    this._setState("SelectorState");
                }

                _handleRight() {
                    this._setState("DoneState");
                }

                _handleEnter() {
                    Router.focusPage();
                }
            },
            class DoneState extends this {
                _getFocused() {
                    return this.tag("Done");
                }

                _handleUp() {
                    this._setState("SelectorState");
                }

                _handleLeft() {
                    this._setState("BackState");
                }

                _handleDown() {}

                _handleEnter() {
                    const code = this._getSelectedLangCode();
                    Language.set(code)
                        .then(() => {
                            storageSetLanguage(code);
                            this.application.emit("appLanguageChanged");
                        })
                        .catch(e => console.log(e));

                    Router.focusPage();
                }
            }
        ];
    }
}
