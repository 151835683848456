import { Colors, Lightning, Router, Utils } from "@lightningjs/sdk";
import TranslatableText from "../../components/TranslatableText/TranslatableText";
import { MENU_PAGE_IDS } from "../../lib/utils";

export default class MenuItem extends Lightning.Component {
    static _template() {
        return {
            x: 0,
            y: 0,
            collision: true,
            zIndex: 82,
            Background: {
                rect: true,
                w: 279,
                h: 60,
                x: 0,
                y: 0,
                color: "transparent"
            },
            Label: {
                x: 18,
                y: 12,
                flex: { direction: "row", alignItems: "center" },
                Icon: {
                    y: 0,
                    w: 24,
                    h: 24,
                    Inactive: { y: 0, x: 0, w: 24, h: 24 },
                    Active: { y: 0, x: 0, w: 24, h: 24, alpha: 0.01 }
                },
                Text: {
                    y: 3,
                    x: 22,
                    type: TranslatableText,
                    text: {
                        fontStyle: 500,
                        fontSize: 24,
                        textColor: Colors("white").get()
                    }
                }
            }
        };
    }

    static get width() {
        return 279;
    }

    static get height() {
        return 60;
    }

    set data(value) {
        this.link = value.link;

        const activeIcon = value.icon.replace(".", "-active.");

        this.patch({
            Label: {
                Icon: {
                    Inactive: {
                        src: Utils.asset(value.icon)
                    },
                    Active: {
                        src: Utils.asset(activeIcon)
                    }
                },
                Text: {
                    key: value.label
                }
            }
        });
    }

    set id(value) {
        this._id = value;
    }

    set activeId(value) {
        this._activeId = value;
        this._select(value);
    }

    _handleClick() {
        this._handleEnter();
    }

    _handleEnter() {
        if (this._id === MENU_PAGE_IDS.exit) {
            this.application.emit("exitApp");
        } else {
            this.application.emit("menuPageSelected");

            Router.navigate(this.link);
        }
    }

    _select(id) {
        if (this._id === id) {
            this._onFocus();
        } else {
            this._onUnfocus();
        }
    }

    _focus() {
        if (this._activeId && this._activeId !== this._id) return;

        this._onFocus();
    }

    _unfocus() {
        if (this._activeId === this._id) return;
        this._onUnfocus();
    }

    _onFocus() {
        this.patch({
            Background: {
                smooth: {
                    color: Colors("focus")
                        .alpha(0.1)
                        .get()
                }
            },
            Label: {
                Icon: {
                    Active: {
                        alpha: 1
                    },
                    Inactive: {
                        alpha: 0.01
                    }
                },
                Text: {
                    text: {
                        textColor: Colors("focus").get()
                    }
                }
            }
        });
    }

    _onUnfocus() {
        this.patch({
            Background: {
                smooth: {
                    color: Colors("focus")
                        .alpha(0)
                        .get()
                }
            },
            Label: {
                Icon: {
                    Active: {
                        alpha: 0.01
                    },
                    Inactive: {
                        alpha: 1
                    }
                },
                Text: {
                    text: {
                        textColor: Colors("white").get()
                    }
                }
            }
        });
    }
}
