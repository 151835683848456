import { Lightning } from "@lightningjs/sdk";
import Button from "../Button/Button";
import TranslatableText from "../TranslatableText/TranslatableText";

export default class Exit extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            w: 584,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            flexItem: { grow: 1 },
            Label: {
                type: TranslatableText,
                key: "exit",
                text: {
                    fontSize: 32
                }
            },
            Actions: {
                flexItem: { marginTop: 36 },
                w: 584,
                h: 96,
                collision: true,
                flex: {
                    direction: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between"
                },
                Cancel: {
                    type: Button,
                    w: 280,
                    h: 96,
                    x: 0,
                    zIndex: 92,
                    label: "no",
                    flexItem: { minWidth: 280 },
                    collision: true,
                    signals: {
                        handleClick: "_setCancelState"
                    }
                },
                Confirm: {
                    type: Button,
                    w: 280,
                    h: 96,
                    zIndex: 92,
                    label: "yes",
                    flexItem: { minWidth: 280 },
                    collision: true,
                    signals: {
                        handleClick: "_setConfirmState"
                    }
                }
            }
        };
    }

    _active() {
        this._setState("CloseState");
    }

    _setCancelState() {
        this._setState("CloseState");
        this._handleEnter();
    }

    _setConfirmState() {
        this._setState("ConfirmState");
        this._handleEnter();
    }

    _handleLeft() {}
    _handleRight() {}
    _handleUp() {}
    _handleDown() {}

    static _states() {
        return [
            class CloseState extends this {
                _getFocused() {
                    return this.tag("Cancel");
                }

                _handleEnter() {
                    this.fireAncestors("$closeExit");
                }

                _handleRight() {
                    this._setState("ConfirmState");
                }
            },
            class ConfirmState extends this {
                _getFocused() {
                    return this.tag("Confirm");
                }

                _handleEnter() {
                    this.fireAncestors("$appExit");
                }

                _handleLeft() {
                    this._setState("CloseState");
                }
            }
        ];
    }
}
