import { Colors, Img, Lightning, Storage, Utils } from "@lightningjs/sdk";
import { FavoriteButton, SeasonSelector } from "../index";
import { MovieDetailMeta, MovieDetailTitle } from "../MovieDetailComponents";
import { getLanguageName, getMetadataTranslated, PLATFORMS } from "../../lib/helpers";
import { appPlatformKey } from "../../lib/storage";

export default class MovieDetail extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            collision: true,
            color: Colors("background").get(),
            w: 1760,
            h: 560,
            Left: {
                collision: true,
                h: 560,
                w: 454,
                flex: {
                    alignItems: "center",
                    justifyContent: "center",
                    direction: "column"
                },
                Title: {
                    type: MovieDetailTitle,
                    w: 454
                },
                Meta: {
                    type: MovieDetailMeta,
                    w: 454,
                    flexItem: { marginBottom: 40 }
                },
                Audio: {
                    alpha: 0.01,
                    flexItem: { minHeight: 52, marginBottom: 6 },
                    w: 454,
                    color: Colors("backgroundContrast").get(),
                    flex: { direction: "row", justifyContent: "center", alignItems: "flex-start", padding: 10 },
                    Icon: {
                        flexItem: {},
                        w: 24,
                        h: 24,
                        y: 3,
                        src: Utils.asset("icons/audio.png")
                    },
                    Text: {
                        w: 300,
                        flexItem: { marginLeft: 12, maxWidth: 300, wrap: true, minHeight: 30 },
                        text: {
                            fontSize: 20,
                            lineHeight: 26
                        }
                    }
                },
                Subs: {
                    alpha: 0.01,
                    flexItem: { minHeight: 52 },
                    flex: { direction: "row", justifyContent: "center", alignItems: "flex-start", padding: 10 },
                    w: 454,
                    color: Colors("backgroundContrast").get(),
                    Icon: {
                        flexItem: {},
                        h: 24,
                        w: 24,
                        y: 3,
                        src: Utils.asset("icons/subs.png")
                    },
                    Text: {
                        w: 300,
                        flexItem: { marginLeft: 12, maxWidth: 300, wrap: true, minHeight: 30 },
                        text: {
                            fontSize: 20,
                            lineHeight: 26
                        }
                    }
                },
                Season: {
                    collision: true,
                    flexItem: { marginTop: 24 },
                    type: SeasonSelector,
                    w: 234,
                    h: 84,
                    signals: {
                        onSeasonClick: "_setSeasonState"
                    }
                }
            },
            Right: {
                collision: true,
                x: 454,
                Background: {
                    h: 560,
                    w: 1306
                },
                OverLay: {
                    rect: true,
                    colorBottom: 0xff000000,
                    colorTop: Colors("background").alpha(0).get(),
                    h: 560,
                    w: 1306
                },
                FlexBox: {
                    collision: true,
                    h: 536,
                    w: 1238,
                    x: 24,
                    flex: {
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        direction: "column",
                        padding: 20,
                        wrap: true
                    },
                    FavoriteButton: {
                        w: 288,
                        h: 84,
                        type: FavoriteButton,
                        signals: {
                            handleClick: "_setFavoriteState"
                        }
                    },
                    Description: {
                        w: 1238,
                        flexItem: { shrink: 1, marginTop: 70, marginRight: 20 },
                        text: {
                            text: "",
                            fontSize: 22,
                            lineHeight: 36,
                            fontStyle: 400
                        }
                    }
                }
            }
        };
    }

    set widgets(value) {
        this._widgets = value;
    }

    set data(value) {
        this.movie = value;

        this.patch({
            Right: {
                Background: {
                    h: 560,
                    w: 1306,
                    texture: Img(value.backdropUrl + "?aspect_ratio=1306:560&width=700").cover(1306, 560)
                },
                FlexBox: {
                    Description: {
                        text: {
                            text: getMetadataTranslated(value).description
                        }
                    },
                    FavoriteButton: {
                        widgets: this._widgets,
                        id: value.movieId,
                        isFavorite: value.isFavorite
                    }
                }
            },

            Left: {
                Title: {
                    data: value
                },
                Meta: {
                    data: value
                },
                Audio: {
                    alpha: value.languages && value.languages.length > 0 ? 1 : 0,
                    Text: {
                        flex: {},
                        text: {
                            text: this._formatLangInfo(value.languages)
                        }
                    }
                },
                Subs: {
                    alpha:
                        Storage.get(appPlatformKey) !== PLATFORMS.zeasn && value.subtitles && value.subtitles.length > 0
                            ? 1
                            : 0,
                    Text: {
                        flex: { wrap: true },
                        text: {
                            text: this._formatLangInfo(value.subtitles)
                        }
                    }
                },
                Season: {
                    season: value.seasonNumber,
                    totalSeasons: value.seasons.length
                }
            }
        });
    }

    _active() {
        // if (this._hasSeasons()) {
        this._setState("SeasonState");
        // } else {
        //     this._setState("FavoriteState");
        // }
    }

    _hasSeasons() {
        return this.movie && this.movie.seasons.length > 1;
    }

    _formatLangInfo(value) {
        return value
            ? value
                  .map((item) => getLanguageName(item))
                  .sort((a, b) => a.localeCompare(b))
                  .join(", ")
            : "";
    }

    _setFavoriteState() {
        this._setState("FavoriteState");
        this.signal("handleClick");
    }

    _setSeasonState() {
        this._setState("SeasonState");
        this.signal("handleClick");
    }

    static _states() {
        return [
            class SeasonState extends this {
                _getFocused() {
                    return this.tag("Season");
                }

                _handleUp() {
                    this._setState("FavoriteState");
                }
            },
            class FavoriteState extends this {
                _getFocused() {
                    return this.tag("FavoriteButton");
                }

                _handleDown() {
                    // if (this._hasSeasons()) {
                    this._setState("SeasonState");
                    // } else {
                    //     return false;
                    // }
                }
            }
        ];
    }
}
