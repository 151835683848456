import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/browser";

// eslint-disable-next-line no-undef
const isDev = process.env.APP_NODE_ENV === "dev";

if (!isDev) {
    Sentry.init({
        dsn: "https://2acd9813ea78465baa30489e95ee1808@o599374.ingest.sentry.io/6620898",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    });
}
