import { Lightning, Router } from "@lightningjs/sdk";
import Button from "../../Button/Button";
import TranslatableText from "../../TranslatableText/TranslatableText";
import QRCode from "qrcode";
import { SUBSCRIPTION_URL } from "../../../lib/utils";

export default class SubscriptionQRPopup extends Lightning.Component {
    static _template() {
        return {
            w: 584,
            collision: true,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            flexItem: { grow: 1 },
            Label: {
                type: TranslatableText,
                key: "subscription",
                text: {
                    fontSize: 32,
                    wordWrapWidth: 583,
                    wordWrap: true
                },
                flexItem: { marginBottom: 24 }
            },
            QrCode: { w: 300, h: 300 },
            Link: {
                flexItem: { marginTop: 24, marginBottom: 48 },
                text: {
                    fontSize: 24,
                    fontStyle: 500
                }
            },

            Close: {
                zIndex: 92,
                collision: true,
                type: Button,
                w: 584,
                h: 96,
                label: "close",
                flexItem: { minWidth: 584 },
                signals: {
                    handleClick: "_handleEnter"
                }
            }
        };
    }

    _getFocused() {
        return this.tag("Close");
    }

    async _active() {
        this._signupLink = SUBSCRIPTION_URL;

        const qrcode = await QRCode.toDataURL(this._signupLink, {
            errorCorrectionLevel: "H",
            type: "image/jpeg",
            quality: 1
        });
        this.patch({
            QrCode: {
                src: qrcode
            },
            Link: {
                text: {
                    text: this._signupLink
                }
            }
        });
    }

    _handleEnter() {
        Router.focusPage();
    }
}
