import { Lightning } from "@lightningjs/sdk";
import { ModalWindow } from "../../components";

export default class Modal extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            zIndex: 80,
            Modal: {
                collision: true,
                type: ModalWindow
            }
        };
    }

    _handleClick() {}

    _getFocused() {
        return this.tag("Modal");
    }

    open(children = [], props = {}) {
        this.tag("Modal").open(children, props);
    }
}
