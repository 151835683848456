import { Lightning, Registry, Storage } from "@lightningjs/sdk";
import { Row } from "@lightningjs/ui-components";
import MoviePoster from "../MoviePoster/MoviePoster";
import { appPlatformKey } from "../../lib/storage";
import { PLATFORMS } from "../../lib/helpers";

export default class MovieList extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            List: {
                collision: true,
                w: 1808,
                type: Row,
                lazyScroll: true,
                alwaysScroll: false,
                neverScroll: false,
                clipping: true,
                lazyUpCount: 12,
                scrollIndex: 0,
                itemTransition: {
                    duration: 0.5,
                    timingFunction: "cubic-bezier(0.20, 1.00, 0.30, 1.00)"
                }
            }
        };
    }

    set w(value) {
        this.patch({
            List: {
                w: value
            }
        });
    }

    get selectedIndex() {
        return this.tag("List").selectedIndex;
    }

    set isRecentlyWatched(value) {
        this._isRecentlyWatched = value;
    }

    set selectedIndex(value) {
        this._selectedIndex = value;
    }

    setSelectedIndex(value) {
        this._selectedIndex = value;
        this.tag("List").selectedIndex = this._selectedIndex;
    }

    set vertical(value) {
        this._vertical = value;
    }

    set itemSpacing(value) {
        this._itemSpacing = value;
    }

    set dimensions(value) {
        this._dimensions = value;
    }

    set data(value) {
        this._data = value;
        this._renderItems = this._data.map((show, index) => {
            return {
                type: MoviePoster,
                show: show,
                index: index,
                isRecentlyWatched: this._isRecentlyWatched,
                ...this._dimensions,
                vertical: this._vertical || false
            };
        });

        // if (this._selectedIndex || this._data.selectedIndex) {
        this.patch({
            List: {
                prevSelected: undefined
            }
        });
        // }

        this.patch({
            List: {
                h: this._dimensions.fH,
                itemSpacing: this._itemSpacing || 8,
                startLazyScrollIndex: this._vertical ? 3 : 6
            }
        });

        this.patch({
            List: {
                items: this._renderItems
            }
        });

        Registry.setTimeout(() => {
            this.tag("List").selectedIndex = this._selectedIndex || this._data.selectedIndex || 0;
        }, 1);
    }

    _handleBack() {
        if (this.tag("List").selectedIndex > 0) {
            this.setSelectedIndex(0);
        } else {
            return false;
        }
    }

    _getFocused() {
        return this.tag("List");
    }

    _init() {
        this.listeners = {
            menuPageSelected: () => {
                this._handleMenuPageSelected();
            }
        };

        if (Storage.get(appPlatformKey) === PLATFORMS.posttv) {
            this.patch({
                List: {
                    itemTransition: {
                        duration: 0
                    }
                }
            });
        }
    }

    _attach() {
        ["menuPageSelected"].forEach(event => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detach() {
        ["menuPageSelected"].forEach(event => {
            this.application.off(event, this.listeners[event]);
        });
    }

    resetIndex() {
        this._selectedIndex = null;
    }

    _handleMenuPageSelected() {
        this.resetIndex();
    }

    $onMovieSelect(event) {
        this._selectedIndex = this.tag("List").selectedIndex;

        this.fireAncestors("$onMovieSelect", { ...event, selectedIndex: this._selectedIndex });
    }

    $movieClicked() {
        this.fireAncestors("$movieClicked", { isRecentSearchList: this.isRecentSearchList });
    }
}
