import { Lightning } from "@lightningjs/sdk";
import { getMovieTitle } from "../../lib/helpers";

export default class MovieDetailTitle extends Lightning.Component {
    static _template() {
        return {
            w: 454,
            flexItem: { maxWidth: 400, wrap: true },
            text: {
                text: "",
                fontSize: 48,
                lineHeight: 52,
                fontStyle: 700,
                textAlign: "center"
            }
        };
    }

    set data(value) {
        this.patch({
            text: {
                text: getMovieTitle(value)
            }
        });
    }
}
