import { Colors, Img, Lightning } from "@lightningjs/sdk";
import { MovieDetailMeta, MovieDetailTitle } from "../MovieDetailComponents";
import { ContinueWatchingButton } from "../index";
import TranslatableText from "../TranslatableText/TranslatableText";

export default class MovieDetailWatching extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            color: Colors("background").get(),
            w: 1760,
            h: 560,
            Right: {
                h: 560,
                w: 454,
                flex: {
                    alignItems: "center",
                    justifyContent: "center",
                    direction: "column"
                },
                Title: {
                    type: MovieDetailTitle,
                    w: 454
                },
                Meta: {
                    type: MovieDetailMeta,
                    w: 454
                },
                EpisodeData: {
                    w: 454,
                    h: 30,
                    flexItem: {
                        marginTop: 64,
                        marginBottom: 16
                    },
                    flex: {
                        alignItems: "center",
                        justifyContent: "center"
                    },
                    Season: {
                        flexItem: { marginRight: 16 },
                        type: TranslatableText,
                        text: {
                            fontStyle: 500,
                            fontSize: 20
                        }
                    },
                    Episode: {
                        flexItem: {},
                        type: TranslatableText,
                        text: {
                            fontStyle: 500,
                            fontSize: 20
                        }
                    }
                },
                ContinueWatching: {
                    type: ContinueWatchingButton,
                    w: 327
                }
            },
            Left: {
                x: 454,
                Background: {
                    h: 560,
                    w: 1306
                }
            }
        };
    }

    set data(value) {
        this.movie = value;

        this.patch({
            Left: {
                Background: {
                    h: 560,
                    w: 1306,
                    texture: Img(value.backdropUrl + "?aspect_ratio=1306:560&width=700").cover(1306, 560)
                }
            },
            Right: {
                Title: {
                    data: value
                },
                Meta: {
                    data: value
                },
                ContinueWatching: {
                    data: value
                },
                EpisodeData: {
                    Season: {
                        translate: { key: "seasonNumber", value: value.parent.movie.seasonNumber }
                    },
                    Episode: {
                        translate: { key: "episodeNumber", value: value.episodeNumber }
                    }
                }
            }
        });
    }

    _getFocused() {
        return this.tag("ContinueWatching");
    }
}
