import { Lightning, Router } from "@lightningjs/sdk";
import TranslatableText from "../TranslatableText/TranslatableText";
import Button from "../Button/Button";

export default class ErrorModal extends Lightning.Component {
    static _template() {
        return {
            w: 584,
            collision: true,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            flexItem: { grow: 1 },
            Label: {
                type: TranslatableText,
                key: "unexpectedErrorTitle",
                text: {
                    fontSize: 32
                }
            },
            Text: {
                w: 584,
                type: TranslatableText,
                key: "unexpectedErrorInfo",
                flexItem: {
                    marginBottom: 48,
                    marginTop: 48
                },
                text: {
                    fontSize: 24,
                    lineHeight: 36
                }
            },
            Button: {
                type: Button,
                label: "back",
                h: 84,
                w: 584,
                zIndex: 92,
                collision: true,
                signals: {
                    handleClick: "_handleEnter"
                }
            }
        };
    }

    _getFocused() {
        return this.tag("Button");
    }

    _active() {
        const isNetworkErr = !navigator.onLine;

        this.patch({
            Label: {
                key: isNetworkErr ? "connectionLost" : "unexpectedErrorTitle"
            },
            Text: {
                key: isNetworkErr ? "connectionLostInfo" : "unexpectedErrorInfo"
            },
            Button: {
                label: isNetworkErr ? "reconnect" : "back"
            }
        });
    }

    _handleEnter() {
        Router.focusPage();

        return false;
    }
}
