// we import all the pages that we want to add to our app
import { Boot, Browse, Error, Favorites, Home, Playback, Search, Series, Settings } from "../pages";
import {
    deviceToken,
    getAppInfo,
    getCategories,
    getFavorites,
    getMovie,
    getMovies,
    getSubscription,
    getTrending
} from "./api";
import { appPlatformKey, storageGetUser, storageSaveUser } from "./storage";

import posthog from "posthog-js";
import { Router, Storage } from "@lightningjs/sdk";
import { PLATFORMS } from "./helpers";
import { posthogCaptureAppStart } from "./utils";

export default {
    boot: () => {
        return new Promise((resolve) => {
            const { platform } = Router.getQueryStringParams();

            (async () => {
                try {
                    const appData = await getAppInfo();
                    Storage.set("app", appData);

                    window.dispatchEvent(
                        new CustomEvent("debug", {
                            detail: `appData: ${JSON.stringify(Storage.get("app"))}`
                        })
                    );
                } catch (e) {
                    console.log(e);

                    window.dispatchEvent(
                        new CustomEvent("debug", {
                            detail: `appData error: ${JSON.stringify(e)}`
                        })
                    );
                }

                const token = await deviceToken();

                posthog.init("phc_94W8u3gmcpRPE5eL4vTy8tQhrZaSzhkCJIkHx0eMsmi", {
                    api_host: "https://app.posthog.com"
                });

                posthog.identify(token, { platform: Storage.get(appPlatformKey) });

                // if (platform === PLATFORMS.posttv) return;

                window.dispatchEvent(
                    new CustomEvent("debug", {
                        detail: `BOOT platform: ${platform}`
                    })
                );

                if (platform === PLATFORMS.amazon && window.Android && window.Android.getAmazonUserId) {
                    window.Android.getAmazonUserId();

                    resolve();
                    return;
                }

                console.log("PLATFORM", Storage.get(appPlatformKey));

                try {
                    if (window.Android?.getUserId) {
                        // amazon
                        const userId = window.Android.getUserId();
                        storageSaveUser({
                            userId,
                            currentPeriodEnd: null
                        });

                        console.log(`GET USER ID ${userId}`);
                    } else if (window.Android?.getPurchaseToken) {
                        // google
                        const userId = window.Android.getPurchaseToken();
                        storageSaveUser({
                            userId,
                            currentPeriodEnd: null
                        });

                        console.log(`GET USER ID ${userId}`);
                    }

                    const subscription = await getSubscription();
                    if (subscription.success) {
                        storageSaveUser(subscription);
                    } else {
                        const user = storageGetUser();
                        storageSaveUser({ ...(user || {}), canWatch: false });
                    }
                } catch (e) {
                    const user = storageGetUser();
                    storageSaveUser({ ...(user || {}), canWatch: false });
                }

                posthogCaptureAppStart();

                resolve();
            })();
        });
    },
    // bootComponent: Boot,
    // First we define a root, this is the hash were the browser will point to
    // at the moment that you boot your app
    root: "home",
    // Next we can define the rest of our routes
    routes: [
        {
            path: "$",
            component: Boot
        },
        {
            // this is a one level deep route.
            path: "home",
            // define the attached Component that the Router will show
            // on this route. If configured the Router will create an instance
            component: Home,
            widgets: ["Menu", "Modal", "AuthPopup", "ExitPopup"],
            on: (page) => {
                return new Promise((resolve, reject) => {
                    getTrending()
                        .then((data) => {
                            posthog.capture("HomePageLoad");

                            // set property on the page
                            page.data = data;
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            },
            options: {
                clearHistory: true
            }
        },
        {
            // this is a one level deep route.
            path: "browse",
            // define the attached Component that the Router will show
            // on this route. If configured the Router will create an instance
            component: Browse,
            widgets: ["Menu", "Modal", "AuthPopup"],
            on: (page) => {
                return new Promise((resolve, reject) => {
                    getCategories()
                        .then((data) => {
                            posthog.capture("BrowsePageLoad");

                            // set property on the page
                            page.data = data;
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            },
            cache: 60 * 30, // 30 minutes
            options: {
                clearHistory: true
            }
        },
        {
            // this is a one level deep route.
            path: "series/:id",
            // define the attached Component that the Router will show
            // on this route. If configured the Router will create an instance
            component: Series,
            widgets: ["Menu", "Modal", "AuthPopup"],
            on: (page, { id }) => {
                return new Promise((resolve, reject) => {
                    getMovies(id)
                        .then((data) => {
                            posthog.capture("MovieSelected", {
                                movie_title: data.movie.title || ""
                            });

                            console.log("PAGE data", data, data.isFavorite);
                            // set property on the page
                            page.data = data;
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            }
        },
        {
            path: "playback/:id",
            component: Playback,
            widgets: ["Modal", "AuthPopup"],
            on: async (page, { id }) => {
                return new Promise((resolve, reject) => {
                    getMovie(id)
                        .then((data) => {
                            // set property on the page
                            page.data = data;

                            if (!data.success) {
                                if (storageGetUser()) {
                                    reject();
                                } else {
                                    resolve();
                                }
                            } else {
                                posthog.capture("Playback", {
                                    movie_title: data.movie.title || ""
                                });

                                resolve();
                            }
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            }
        },
        {
            path: "favorites",
            component: Favorites,
            widgets: ["Menu", "Modal", "AuthPopup"],
            on: (page) => {
                return new Promise((resolve, reject) => {
                    getFavorites()
                        .then((data) => {
                            posthog.capture("FavoritesPageLoad");

                            // set property on the page
                            page.data = data;
                            resolve();
                        })
                        .catch((e) => {
                            reject(e);
                        });
                });
            },
            options: {
                clearHistory: true
            }
        },
        {
            path: "Search",
            component: Search,
            widgets: ["Menu", "Modal", "AuthPopup"],
            cache: 60 * 30, // 30 minutes,
            on: () => {
                return new Promise((resolve) => {
                    posthog.capture("SearchPageLoad");

                    resolve();
                });
            },
            options: {
                clearHistory: true
            }
        },
        {
            path: "Settings",
            component: Settings,
            widgets: ["Menu", "Modal", "AuthPopup"],
            on: () => {
                return new Promise((resolve) => {
                    posthog.capture("SettingPageLoad");

                    resolve();
                });
            }
        },
        {
            path: "!",
            component: Error,
            widgets: ["Modal"],
            on: () => {
                return new Promise((resolve) => {
                    posthog.capture("Error");

                    resolve();
                });
            }
        }
    ]
};
