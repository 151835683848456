import { Colors, Lightning } from "@lightningjs/sdk";

export default class LanguageSelectorItem extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            zIndex: 92,
            h: 60,
            w: 584,
            Box: {
                rect: true,
                color: Colors("background")
                    .alpha(0)
                    .get(),
                h: 60,
                w: 584,
                Label: {
                    w: 584,
                    y: 16,
                    text: {
                        text: "",
                        fontSize: 24,
                        fontStyle: 500,
                        textAlign: "center"
                    }
                }
            }
        };
    }

    get lang() {
        return this._lang;
    }

    set lang(value) {
        this._lang = value;
        this.patch({
            Box: {
                Label: {
                    text: {
                        text: value.name
                    }
                }
            }
        });
    }

    _init() {
        this.listeners = {
            languageSelected: code => {
                this._select(code);
            }
        };
    }

    _attach() {
        ["languageSelected"].forEach(event => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detach() {
        ["languageSelected"].forEach(event => {
            this.application.off(event, this.listeners[event]);
        });
    }

    _handleClick() {
        this.fireAncestors("$languageSelected");

        this.collectionWrapper.setIndex(this.parent.componentIndex);
    }

    _focus() {
        this._select(null, true);
    }

    _unfocus() {
        this.patch({
            Box: {
                smooth: {
                    color: Colors("background")
                        .alpha(0)
                        .get()
                },
                Label: {
                    text: {
                        textColor: Colors("white").get()
                    }
                }
            }
        });
    }

    _select(code, force) {
        if (force || code === this._lang.code) {
            this.patch({
                Box: {
                    smooth: {
                        color: Colors("focus")
                            .alpha(0.1)
                            .get()
                    },
                    Label: {
                        text: {
                            textColor: Colors("focus").get()
                        }
                    }
                }
            });
        }
    }
}
