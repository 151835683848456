import { Language, Lightning, Registry, Router, VideoPlayer, Storage } from "@lightningjs/sdk";
import { LoadingCircle, PlayerBottom, PlayerTop } from "../components";
import { getMovie, saveMovieProgress } from "../lib/api";
import VideoPlayedExtended from "../lib/video-player";
import ModalSelector from "../components/ModalSelector/ModalSelector";
import { getAudioLanguageName, getLanguageName } from "../lib/helpers";
import { storageGetCurrentShow, storageGetUser, storagePrevMovieIdKey } from "../lib/storage";
import { BACK_KEYS, pageTransition } from "../lib/utils";

export default class Playback extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            w: 1920,
            h: 1080,
            Loading: {
                type: LoadingCircle
            },
            Top: {
                type: PlayerTop,
                signals: {
                    triggerBack: "_doBack"
                }
            },
            Bottom: {
                type: PlayerBottom
            }
        };
    }

    _construct() {
        this.skipSeconds = 15;
        this._paused = true;
    }

    pageTransition(pageIn) {
        return pageTransition(pageIn);
    }

    set data(data) {
        this._handlePlaybackData(data);
    }

    set params(args) {
        this._params = args;
    }

    _getFocused() {
        return this.tag("Bottom");
    }

    _firstActive() {
        VideoPlayer.consumer(this);
    }

    _init() {
        this.listeners = {
            modalItemSaved: (event) => {
                this._modalItemSaved(event);
            },
            modalClosed: () => {
                this._modalClosed();
            },
            closedLoggedInPopup: async () => {
                const data = await getMovie(this._params.id);

                this._handlePlaybackData(data);
            },
            loginClosed: () => {
                this._doBack();
            }
        };

        // VideoPlayer.close();
        // VideoPlayer.consumer(this);
        // VideoPlayer.loader(loader);
        // VideoPlayer.unloader(unloader);
    }

    _active() {
        [("closedLoggedInPopup", "loginClosed")].forEach((event) => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _inactive() {
        [("closedLoggedInPopup", "loginClosed")].forEach((event) => {
            this.application.off(event, this.listeners[event]);
        });

        this._dispatchVideo();
    }

    _detach() {
        this._detachModalEvents();
    }

    _attachEvents() {
        ["modalItemSaved", "modalClosed"].forEach((event) => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detachModalEvents() {
        ["modalItemSaved", "modalClosed"].forEach((event) => {
            this.application.off(event, this.listeners[event]);
        });
    }

    _captureKey() {
        this._hideControls();

        return false;
    }

    _handleStop() {
        this._doBack();
    }

    _handleEnter() {
        // VideoPlayer.playPause();
    }

    _handleClick() {
        this._setState("BottomMenuState");
    }

    _handleBack() {
        if (!this._paused) {
            this._setState("WatchingState");
        } else {
            this._doBack();
        }
    }

    _handleUp() {
        this._setState("TopMenuState");
    }

    _handleDown() {
        this._setState("BottomMenuState");
    }

    _handlePlayPause() {
        this._setState("BottomMenuState");
        this.tag("Bottom").reset();
        this.$triggerPlayPause();
    }

    _handlePlay() {
        this._setState("BottomMenuState");
        this.tag("Bottom").reset();
        this.$triggerPlay();
    }

    _handlePause() {
        this._setState("BottomMenuState");
        this.tag("Bottom").reset();
        this.$triggerPause();
    }

    _handleRewind() {
        this._setState("BottomMenuState");
        this.tag("Bottom").rewind();
        this.$triggerRewind();
    }

    _handleForward() {
        this._setState("BottomMenuState");
        this.tag("Bottom").forward();
        this.$triggerForward();
    }

    _handlePlaybackData(data) {
        this._data = data;

        if (data.success) {
            this._toggleInit();
        } else {
            this._hideControls();
            this._hideLoading();

            this.widgets.authpopup.open();
            Router.focusWidget("AuthPopup");
        }
    }

    _toggleInit() {
        this._initVideo();

        this.patch({
            Bottom: {
                currentTime: 0,
                duration: 0
            }
        });
    }

    _modalClosed() {
        this._selecting = false;

        this._hideControls();
    }

    _modalItemSaved(id) {
        this._selecting = false;

        if (this._savingAudio) {
            this.videoPlayer.selectAudio(id);
            this._setAudio(id);
        } else {
            this.videoPlayer.selectSubs(id);
            this._setSubs(id);
        }

        this._detachModalEvents();

        this._hideControls();
    }

    _dispatchVideo() {
        Registry.clearTimeouts();

        this.application.emit("showBackground");

        VideoPlayer.pause();
        VideoPlayer.close();

        this.stage.gc();

        this.videoPlayer = null;
    }

    _doBack() {
        this._dispatchVideo();

        const show = storageGetCurrentShow();

        const history = Router.getHistory();

        const index = history
            .slice()
            .reverse()
            .findIndex((h) => h.hash.lastIndexOf(show && !show.continueWatching ? "series/" : "home") > -1);

        if (show && !show.continueWatching) {
            Storage.set(storagePrevMovieIdKey, this._params.id);
        }

        // in a normal flow this cond will always be true
        if (index > -1) {
            Router.go(index + 1);
        } else {
            if (show) {
                Router.navigate("series/" + show.id);
            } else {
                Router.back();
            }
        }
    }

    _setSubs(code) {
        this._selectedSub = code;

        const lang = this._data.playbackData.subtitles.find((l) => l.languageCode === code);

        this.patch({
            Top: {
                selectedSub: lang ? lang.language : code
            }
        });
    }

    _setAudio(code) {
        this._selectedAudio = code;

        this.patch({
            Top: {
                selectedAudio: code
            }
        });
    }

    _initVideo() {
        if (!this.attached) return;

        this.stage.gc();

        this.application.emit("clearBackground");

        this._currentTime = 0;

        if (this._seekingTimeout) {
            Registry.clearTimeout(this._seekingTimeout);
            this._seekingTimeout = null;
        }
        this._savedEndProggress = false;
        this._autoplayWorked = false;
        this._seeking = false;
        this._paused = true;
        this._videoInit = true;
        this._duration = null;

        this._startTime = this._data && this._data.leftOff ? this._data.leftOff.time : 0;

        const show = storageGetCurrentShow();

        this._nextEpisode = null;
        if (show) {
            const currentEp = show.episodes.find((ep) => ep.id === this._data.movie.movieId);
            if (currentEp) {
                this._nextEpisode = show.episodes.find((ep) => ep.episodeNumber === currentEp.episodeNumber + 1);
            }
        }

        if (!this.videoPlayer) {
            this.videoPlayer = new VideoPlayedExtended();
        }

        this.videoPlayer.on("selectedSubs", (code) => {
            this._setSubs(code);
        });

        this.videoPlayer.on("selectedAudio", (code) => {
            this._setAudio(code);
        });

        this.videoPlayer.on("audioTracksUpdated", (tracks) => {
            this._audioTracks = tracks;
        });

        const loader = (url, videoEl) => {
            return new Promise((resolve) => {
                this.videoPlayer.create(url, videoEl, this._data.playbackData, this._data.leftOff);

                resolve();
            });
        };

        const unLoader = (url, videoEl) => {
            return new Promise((resolve) => {
                if (this.videoPlayer) {
                    this.videoPlayer.destroy(videoEl);
                }

                resolve();
            });
        };

        VideoPlayer.unloader(unLoader);
        VideoPlayer.loader(loader);
        // VideoPlayer.open("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4");
        // VideoPlayer.open("https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8");
        VideoPlayer.open(this._data.playbackData.playbackUrl);
    }

    async _saveProgress(time) {
        if (this.saving || this._videoInit || this._savedEndProggress) return;
        this.saving = true;

        const finished = !this._nextEpisode && (time / this._duration) * 100 > 93;

        // console.log("ACTUALLY SAVE", this._data, finished ? 0 : time);
        try {
            await saveMovieProgress(this._data.movie.movieId, finished ? 0 : time);

            if (finished) {
                this._savedEndProggress = true;
            }
        } catch (e) {
            console.log("e", e);
        }

        const _this = this;
        Registry.setTimeout(() => {
            _this.saving = false;
        }, 3000);
    }

    _hideLoading() {
        this.patch({
            Loading: {
                smooth: {
                    alpha: 0
                }
            }
        });
    }

    _handleVideoProgress() {
        this._currentTime = Math.max(0, this._currentTime);
        this._currentTime = Math.min(this._duration, this._currentTime);

        this.patch({
            Bottom: {
                currentTime: this._currentTime
            }
        });

        if (storageGetUser()) {
            (async () => {
                await this._saveProgress(this._currentTime);
            })();
        }
    }

    _cancelHideControls() {
        if (this._watchingStateTimeout) {
            Registry.clearTimeout(this._watchingStateTimeout);
            this._watchingStateTimeout = null;
        }
    }

    _hideControls() {
        this._cancelHideControls();

        if (!this._selecting) {
            this._watchingStateTimeout = Registry.setTimeout(() => {
                this._setState("WatchingState");
                this._watchingStateTimeout = null;
            }, 3500);
        }
    }

    _handleSeeking() {
        VideoPlayer.pause();
        this._seeking = true;

        if (this._seekingTimeout) {
            Registry.clearTimeout(this._seekingTimeout);
            this._seekingTimeout = null;
        }

        if (this._skipTimeout) {
            Registry.clearTimeout(this._skipTimeout);
        }
        this._skipTimeout = Registry.setTimeout(() => {
            VideoPlayer.skip(this._currentTime - VideoPlayer.currentTime);
            this._skipTimeout = null;
        }, 350);

        this._seekingTimeout = Registry.setTimeout(() => {
            this._seekingTimeout = null;

            if (!this._paused) {
                VideoPlayer.play();
            }
            this._seeking = false;
        }, 1000);
    }

    _handleExit() {
        this._dispatchVideo();

        const show = storageGetCurrentShow();

        if (show) {
            if (this._nextEpisode) {
                Router.navigate("playback/" + this._nextEpisode.id);
            } else {
                this._doBack();
            }
        } else {
            this._doBack();
        }
    }

    _tryAutoplay() {
        if (this._videoInit) {
            if (this._autoplayTimeout) return;
            this._autoplayTimeout = Registry.setTimeout(() => {
                if (this._videoInit && Math.floor(VideoPlayer.currentTime) === Math.floor(this._startTime)) {
                    this._autoplayTimeout = null;
                    this._dispatchVideo();
                    this._initVideo();
                }
            }, 6000);
        }
    }

    $triggerPlayPause() {
        VideoPlayer.playPause();

        this._paused = VideoPlayer.playing;

        if (this._autoplayTimeout) {
            Registry.clearTimeout(this._autoplayTimeout);
        }
    }

    $triggerPlay() {
        VideoPlayer.play();

        this._paused = VideoPlayer.playing;
    }

    $triggerPause() {
        VideoPlayer.pause();

        this._paused = VideoPlayer.playing;

        if (this._autoplayTimeout) {
            Registry.clearTimeout(this._autoplayTimeout);
        }
    }

    $triggerRewind() {
        if (this._videoInit) return;

        this._handleSeeking();

        this._currentTime -= this.skipSeconds;

        this._handleVideoProgress();
    }

    $triggerForward() {
        if (this._videoInit) return;

        this._handleSeeking();

        this._currentTime += this.skipSeconds;

        this._handleVideoProgress();
    }

    $videoPlayerSeeking() {
        if (!this._paused) {
            this.patch({
                Loading: {
                    smooth: {
                        alpha: 1
                    }
                }
            });

            VideoPlayer.play();

            this._tryAutoplay();
        }
    }

    $videoPlayerWaiting() {
        this.patch({
            Loading: {
                smooth: {
                    alpha: 1
                }
            }
        });

        this._tryAutoplay();
    }

    $videoPlayerCanPlay() {
        this._hideLoading();
    }

    $videoPlayerPlaying() {
        this._paused = false;

        this._hideLoading();

        this._hideControls();
    }

    $videoPlayerPlay() {
        console.log("PLAY");
        this._hideLoading();

        this._paused = false;

        this.patch({
            Bottom: {
                isPlaying: true
            }
        });
    }

    $videoPlayerPause() {
        if (this._seeking) return;

        this._paused = true;

        this.patch({
            Bottom: {
                isPlaying: false
            }
        });
    }

    $videoPlayerDurationChange() {
        // console.log("DUR CHANGE", this._duration);

        const changeTime = !this._duration;

        this._duration = VideoPlayer.duration;
        this.patch({
            Bottom: {
                duration: VideoPlayer.duration
            }
        });

        if (changeTime && this._data.leftOff) {
            const startFrom = this._data.leftOff.time;
            const lastSeconds = 10;
            if (startFrom && startFrom < this._duration && this._duration - startFrom > lastSeconds) {
                VideoPlayer.seek(startFrom);
            }
        }
    }

    $videoPlayerTimeUpdate() {
        // console.log("VIDEO PROGRESS", this._currentTime, VideoPlayer.currentTime);
        // console.log("PROGRESS SEEKING", this._seeking);

        if (this._duration && VideoPlayer.currentTime && this._duration - VideoPlayer.currentTime <= 2) {
            this._handleExit();
            return;
        }

        if (this._videoInit && VideoPlayer.currentTime === this._startTime) return;

        if ((!VideoPlayer.playing && !this._videoInit) || this._seeking) return;

        this._currentTime = VideoPlayer.currentTime;

        this._handleVideoProgress();

        this._videoInit = false;
        this._autoplayWorked = true;
        this._paused = false;

        this.patch({
            Bottom: {
                isPlaying: true
            }
        });

        this._hideLoading();
    }

    $videoPlayerEnded() {
        this._handleExit();
    }

    $onAudioSelect() {
        this._savingAudio = true;
        this.$showSelector(
            this._selectedAudio,
            this._audioTracks.map((track) => {
                return { id: track.lang, label: getAudioLanguageName(track.lang) };
            }),
            "audio"
        );
    }

    $onSubsSelect() {
        this._savingAudio = false;
        this.$showSelector(
            this._selectedSub,
            [
                {
                    id: "off",
                    label: Language.translate("off")
                },
                ...this._data.playbackData.subtitles.map((item) => {
                    return {
                        id: item.languageCode,
                        label: getLanguageName(item.language)
                    };
                })
            ],
            "subtitles"
        );
    }

    $showSelector(defaultId, items, label) {
        this._cancelHideControls();

        this._selecting = true;

        this._attachEvents();
        this.widgets.modal.open([
            {
                type: ModalSelector,
                label,
                defaultId,
                items
            }
        ]);

        Router.focusWidget("Modal");
    }

    static _states() {
        return [
            class WatchingState extends this {
                $enter() {
                    this.patch({
                        Top: {
                            smooth: {
                                y: -1 * PlayerTop.h
                            }
                        },
                        Bottom: {
                            smooth: {
                                y: 1080
                            }
                        }
                    });

                    this.tag("Bottom").reset();
                    this.tag("Top").reset();
                }

                $exit() {
                    this.patch({
                        Top: {
                            smooth: {
                                y: 0
                            }
                        },
                        Bottom: {
                            smooth: {
                                y: 1080 - PlayerBottom.h
                            }
                        }
                    });
                }

                _getFocused() {
                    return undefined;
                }

                _captureKey({ keyCode, key, code, keyIdentifier }) {
                    const keyPressed = key || code || keyCode || keyIdentifier;

                    if (BACK_KEYS.indexOf(keyPressed) > -1) {
                        this._doBack();
                        // } else if ([85, 179, 503, 10252, 126, 127, 415, 413, 19, 412, 417].indexOf(keyPressed) > -1) {
                        // playpause, forward, rewind

                        // this._setState("BottomMenuState");
                    } else {
                        this._setState("BottomMenuState");

                        if (typeof window.VK_PLAY !== "undefined") {
                            switch (keyCode) {
                                case window.VK_PLAY:
                                    this._handlePlay();
                                    break;
                                case window.VK_STOP:
                                    this._handleStop();
                                    break;
                                case window.VK_PAUSE:
                                    this._handlePause();
                                    break;
                                case window.VK_BACK:
                                case window.VK_BACK_SPACE:
                                    this._doBack();
                                    break;
                                case window.VK_RIGHT:
                                case window.VK_FAST_FORWARD:
                                    this._handleForward();
                                    break;
                                case window.VK_LEFT:
                                case window.VK_REWIND:
                                    this._handleRewind();
                                    break;
                            }
                        }

                        // prevent focus on top bar
                        return keyIdentifier === "Up";
                    }
                }
            },

            class TopMenuState extends this {
                $enter() {
                    this._hideControls();
                }

                _getFocused() {
                    return this.tag("Top");
                }
            },

            class BottomMenuState extends this {
                _getFocused() {
                    return this.tag("Bottom");
                }
            }
        ];
    }
}
