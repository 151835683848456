import { Colors, Lightning, Router, Storage } from "@lightningjs/sdk";
import { BackButton, MovieDetail, MovieList } from "../components";
import { pageTransition, POSTER_HORIZONTAL_DIMENSIONS } from "../lib/utils";
import { handleMovieSelect, PLATFORMS } from "../lib/helpers";
import ModalSelector from "../components/ModalSelector/ModalSelector";
import { appPlatformKey, storagePrevMovieIdKey } from "../lib/storage";

export default class Series extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            collision: true,
            w: 1920,
            h: 1080,
            color: Colors("pageBackground").get(),
            Page: {
                collision: true,
                x: 112,
                w: 1808,
                h: 1080,
                BackBtn: {
                    y: 32,
                    type: BackButton,
                    collision: true,
                    signals: {
                        goBack: "_doBack"
                    }
                },
                MovieDetail: {
                    type: MovieDetail,
                    collision: true,
                    y: 76,
                    x: 0,
                    signals: {
                        handleClick: "_setDetailsState"
                    }
                },
                CategoryList: {
                    type: MovieList,
                    y: 660
                }
            }
        };
    }

    set data({ movie }) {
        this._movie = movie;
        this._movie.isFavorite = movie.isFavorite;

        if (movie.episodes) {
            this._renderCategoryList();
        }
    }

    _active() {
        this._setState("ListState");
    }

    _getFocused() {
        // this._setState("ListState");

        return this.tag("CategoryList");
    }

    _handleLeft() {
        Router.focusWidget("Menu");
    }

    _renderCategoryList() {
        const prevMovieId = Storage.get(storagePrevMovieIdKey);

        if (prevMovieId) {
            Storage.remove(storagePrevMovieIdKey);
            this.patch({
                Page: {
                    CategoryList: {
                        selectedIndex: this._movie.episodes.findIndex((m) => m.movieId === parseInt(prevMovieId))
                    }
                }
            });
        }

        this.patch({
            Page: {
                MovieDetail: {
                    widgets: this.widgets,
                    data: this._movie
                },
                CategoryList: {
                    dimensions:
                        Storage.get(appPlatformKey) === PLATFORMS.posttv
                            ? POSTER_HORIZONTAL_DIMENSIONS.equalS
                            : POSTER_HORIZONTAL_DIMENSIONS.s,
                    data: this._movie.episodes
                }
            }
        });
    }

    _init() {
        this.listeners = {
            modalItemSaved: (event) => {
                this._modalItemSaved(event);
            }
        };
    }

    _detach() {
        this._detachEvents();
    }

    _attachEvents() {
        ["modalItemSaved"].forEach((event) => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detachEvents() {
        ["modalItemSaved"].forEach((event) => {
            this.application.off(event, this.listeners[event]);
        });
    }

    _doBack() {
        this.tag("CategoryList").resetIndex();

        const history = Router.getHistory();

        const index = history
            // .slice()
            // .reverse()
            .findIndex((h) => h.hash.indexOf("series/") > -1);

        // in a normal flow this cond will always be true
        if (index > -1) {
            Router.navigate(history[index - 1].hash);
        } else {
            if (history.length === 0) {
                Router.navigate("home");
            } else {
                Router.back();
            }
        }

        // Router.back();
    }

    _handleBack() {
        this._doBack();
    }

    _modalItemSaved(id) {
        if (id !== this._movie.movieId) {
            Router.navigate(`series/${id}`);
        }

        this._detachEvents();
    }

    _setDetailsState() {
        this._setState("MovieDetailState");
    }

    $onSeasonSelect() {
        this._attachEvents();
        this.widgets.modal.open([
            {
                type: ModalSelector,
                handleModalSave: this._modalItemSaved,
                defaultId: this._movie.movieId,
                items: this._movie.seasons.map((item) => {
                    return {
                        id: item.id,
                        translate: {
                            key: "seasonNumber",
                            value: item.seasonNumber
                        }
                    };
                })
            }
        ]);

        Router.focusWidget("Modal");
    }

    $onMovieSelect(event) {
        handleMovieSelect(this.widgets, { ...event, parent: this._movie });
    }

    $movieClicked() {
        this._setState("ListState");
    }

    pageTransition(pageIn) {
        return pageTransition(pageIn);
    }

    static _states() {
        return [
            class BackButtonState extends this {
                _getFocused() {
                    return this.tag("BackBtn");
                }

                _handleEnter() {
                    this._doBack();
                }

                _handleDown() {
                    this._setState("MovieDetailState");
                }
            },
            class MovieDetailState extends this {
                _getFocused() {
                    return this.tag("MovieDetail");
                }

                _handleUp() {
                    this._setState("BackButtonState");
                }

                _handleDown() {
                    this._setState("ListState");
                }
            },
            class ListState extends this {
                _getFocused() {
                    return this.tag("CategoryList");
                }

                _handleUp() {
                    this._setState("MovieDetailState");
                }
            }
        ];
    }
}
