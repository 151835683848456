import { Storage } from "@lightningjs/sdk";

const userKey = "user";
const languageKey = "selectedLanguage";

const preferredSubsKey = "preferredSub";
const preferredAudioKey = "preferredAudio";

const episodeListKey = "episodeList";

export const appPlatformKey = "appPlatform";
export const appPlatformTokenKey = "appPlatformToken";

export const storageAppStartTimeKey = "appStartTimeKey";

export const storagePrevMovieIdKey = "prevMovieIdKey";

export const storageRemoveUser = () => {
    Storage.remove(userKey);
};

export const storageSaveUser = user => {
    if (user && user.userId) {
        Storage.set(userKey, JSON.stringify(user));
    } else {
        storageRemoveUser();
    }
};

export const storageGetUser = () => {
    const user = Storage.get(userKey);

    return user ? (typeof user === "string" ? JSON.parse(user) : user) : null;
};

export const storageGetLanguage = () => {
    return Storage.get(languageKey);
};

export const storageSetLanguage = code => {
    Storage.set(languageKey, code);
};

export const storageGetPreferredSubtitles = () => {
    return Storage.get(preferredSubsKey) || "off";
};

export const storageSetPreferredSubtitles = langCode => {
    return Storage.set(preferredSubsKey, langCode);
};

export const storageGetPreferredAudio = () => {
    return Storage.get(preferredAudioKey) || storageGetLanguage() || "en";
};

export const storageSetPreferredAudio = langCode => {
    return Storage.set(preferredAudioKey, langCode);
};

export const storageSetCurrentShow = item => {
    Storage.set(episodeListKey, item);
};

export const storageGetCurrentShow = () => {
    return Storage.get(episodeListKey) || null;
};
