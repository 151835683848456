import { Lightning, Utils } from "@lightningjs/sdk";

export default class PlayForward extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            zIndex: 92,
            w: 37.5,
            h: 25,
            src: Utils.asset("icons/play-forward.png")
        };
    }

    _focus() {
        this.patch({
            src: Utils.asset(`icons/play-forward-active.png`)
        });
    }

    _unfocus() {
        this.patch({
            src: Utils.asset(`icons/play-forward.png`)
        });
    }

    _handleClick() {
        this.signal("handleClick");
    }
}
