import { Colors, Lightning } from "@lightningjs/sdk";
import { PlayRewind, PlayForward, PlayPause, Progress } from "../index";

export default class PlayerBottom extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            collision: true,
            color: Colors("black")
                .alpha(0.5)
                .get(),
            y: 1080 - 155,
            x: 0,
            h: 155,
            w: 1920,
            // zIndex: 102,
            Progress: {
                type: Progress,
                flexItem: { margin: 10 },
                h: 36
            },
            Actions: {
                collision: true,
                y: 80,
                x: 1920 / 2 - 227 / 2,
                w: 227,
                flex: { direction: "row", paddingX: 37, alignItems: "center", justifyContent: "space-between" },
                PlayRewind: {
                    type: PlayRewind,
                    signals: {
                        handleClick: "_handleRewindClick"
                    }
                },
                PlayPause: {
                    type: PlayPause,
                    signals: {
                        handleClick: "_handlePlayPauseClick"
                    }
                },
                PlayForward: {
                    type: PlayForward,
                    signals: {
                        handleClick: "_handleForwardClick"
                    }
                }
            }
        };
    }

    static get h() {
        return 155;
    }

    set isPlaying(value) {
        this.patch({
            Actions: {
                PlayPause: {
                    isPlaying: value
                }
            }
        });
    }

    set duration(value) {
        this.patch({
            Progress: {
                duration: value
            }
        });
    }

    set currentTime(value) {
        this.patch({
            Progress: {
                currentTime: value
            }
        });
    }

    _init() {
        this._setState("Play");
    }

    _getFocused() {
        return this.tag("PlayPause");
    }

    reset() {
        this._setState("Play");
    }

    rewind() {
        this._setState("Rewind");
    }

    forward() {
        this._setState("Forward");
    }

    _handleRewindClick() {
        this._triggerRewind();
        this.rewind();
    }

    _handlePlayPauseClick() {
        this.fireAncestors("$triggerPlayPause");
        this.reset();
    }

    _handleForwardClick() {
        this._triggerForward();

        this.forward();
    }

    _triggerRewind() {
        this.fireAncestors("$triggerRewind");
    }

    _triggerForward() {
        this.fireAncestors("$triggerForward");
    }

    static _states() {
        return [
            class Rewind extends this {
                _getFocused() {
                    return this.tag("PlayRewind");
                }

                _handleRight() {
                    this._setState("Play");
                }

                _handleLeft() {
                    this._triggerRewind();
                }

                _handleEnter() {
                    this.fireAncestors("$triggerRewind");
                }
            },
            class Play extends this {
                _getFocused() {
                    return this.tag("PlayPause");
                }

                _handleLeft() {
                    this._setState("Rewind");
                }

                _handleRight() {
                    this._setState("Forward");
                }
            },
            class Forward extends this {
                _getFocused() {
                    return this.tag("PlayForward");
                }

                _handleLeft() {
                    this._setState("Play");
                }

                _handleEnter() {
                    this._triggerForward();
                }

                _handleRight() {
                    this.fireAncestors("$triggerForward");
                }
            }
        ];
    }
}
