import { Lightning, Utils } from "@lightningjs/sdk";

export default class PlayRewind extends Lightning.Component {
    static _template() {
        return {
            w: 37.5,
            h: 25,
            src: Utils.asset("icons/play-back.png"),
            collision: true,
            zIndex: 92
        };
    }

    _focus() {
        this.patch({
            src: Utils.asset(`icons/play-back-active.png`)
        });
    }

    _unfocus() {
        this.patch({
            src: Utils.asset(`icons/play-back.png`)
        });
    }

    _handleClick() {
        this.signal("handleClick");
    }
}
