import { Colors, Img, Lightning, Registry, Storage, Utils } from "@lightningjs/sdk";
import TranslatableText from "../TranslatableText/TranslatableText";
import { getCategoryNamesTranslated, getMovieTitle, PLATFORMS } from "../../lib/helpers";
import { appPlatformKey } from "../../lib/storage";

export default class MoviePoster extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            rect: true,
            color: "transparent",
            clipping: true,
            Background: {},
            BackgroundFocused: { alpha: 0.01 },
            BackgroundFull: {
                rect: true,
                clipping: true,
                color: Colors("pageBackground")
                    .alpha(0.01)
                    .get(),
                alpha: 0.01
            },
            EpisodeNumber: {
                h: 48,
                w: 48,
                alpha: 0,
                Text: {
                    w: 48,
                    h: 48,
                    y: 3,
                    text: {
                        textColor: Colors("focus").get(),
                        fontSize: 32,
                        lineHeight: 48,
                        fontStyle: 600,
                        textAlign: "center"
                    }
                }
            },
            Label: {
                x: 0,
                rect: true,
                color: Colors("focus")
                    .alpha(0)
                    .get(),
                alpha: 0,
                flex: {
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: 2
                },
                Categories: {
                    x: 48,
                    // h: 40,
                    type: TranslatableText,
                    text: {
                        fontSize: 30,
                        // lineHeight: 36,
                        fontStyle: 400,
                        textColor: Colors("background").get()
                    }
                },
                Icon: {
                    flexItem: { marginLeft: 24, marginRight: 48 },
                    w: 24,
                    h: 24,
                    src: Utils.asset("icons/go-active.png")
                }
            },
            Border: {}
        };
    }

    set index(value) {
        this._index = value;
    }

    set isRecentlyWatched(value) {
        this._isRecentlyWatched = value;
    }

    set fW(value) {
        this._fW = value;

        this.patch({
            BackgroundFull: {
                w: value
            }
        });
    }

    set fH(value) {
        this._fH = value;
        this._posterH = value;

        this.patch({
            BackgroundFull: {
                h: value
            }
        });
    }

    set lH(value) {
        this._lH = value;
        if (Storage.get(appPlatformKey) === PLATFORMS.posttv) {
            this._posterH -= value;
        }

        this.patch({
            Label: {
                h: value,
                y: this._fH - value
            }
        });
    }

    set lF(value) {
        this.patch({
            Label: {
                Categories: {
                    text: {
                        fontSize: value
                        // lineHeight: value + 4
                    }
                }
            }
        });
    }

    set vertical(value) {
        this._vertical = value;
        if (value === true) {
            this.patch({
                y: this._vertical ? (this._fH - this.h) / 2 : 0
            });
        }
    }

    set show(data) {
        this._data = data;
    }

    _init() {
        this._width = this.w;
        this._height = this.h;

        this.listeners = {
            categoryFocus: category => {
                this._triggerResize(category !== this._data.category, true);
            },
            categoryBlur: category => {
                this._triggerResize(category !== this._data.category, false);
            }
        };

        this._adjustLabel();

        if (!this._vertical && this._height !== this._fH) {
            this.patch({
                BackgroundFocused: {
                    h: this._fH,
                    w: this._width
                }
            });
        }

        this.patch({
            h: this._height,
            BackgroundFull: {
                w: this._width
            }
        });

        if (this._data.isEpisode && !this._isRecentlyWatched) {
            this.patch({
                Background: {
                    h: this._height,
                    w: this._width
                },
                EpisodeNumber: {
                    rect: true,
                    color: Colors("pageBackground")
                        .alpha(0.5)
                        .get(),
                    alpha: 1,
                    Text: {
                        text: {
                            text: this._data.episodeNumber
                        }
                    }
                },
                Label: {
                    Categories: {
                        translate: { key: "episodeNumber", value: this._data.episodeNumber }
                    }
                }
            });
        } else {
            this.patch({
                Background: {
                    h: this._height,
                    w: this._width
                }
            });

            if (!this._isRecentlyWatched) {
                const categories = this._data.categories ? this._data.categories.slice(0, 2) : [];
                this.patch({
                    Label: {
                        Categories: {
                            text: getCategoryNamesTranslated(categories)
                        }
                    }
                });
            } else {
                this.patch({
                    Label: {
                        Categories: {
                            translate: {
                                key: "continueWatchingMovie",
                                value: {
                                    title: getMovieTitle(this._data.parent.movie),
                                    season: this._data.parent.movie.seasonNumber,
                                    episode: this._data.episodeNumber
                                }
                            }
                        }
                    }
                });
            }
        }

        if (Storage.get(appPlatformKey) === PLATFORMS.posttv) {
            this._makeBorder();
        }
    }

    _active() {
        const bgHeight = Storage.get(appPlatformKey) === PLATFORMS.posttv ? this._posterH : this._height;
        this.patch({
            Background: {
                Img: {
                    w: this._width,
                    h: bgHeight,
                    src: this.getImg(
                        this._data.isEpisode && !this._isRecentlyWatched ? this._data.backdropUrl : this._data.coverUrl,
                        this._width,
                        bgHeight
                    ).src
                }
            }
        });

        if (Storage.get(appPlatformKey) !== PLATFORMS.posttv) {
            this.patch({
                BackgroundFocused: {
                    Img: {
                        w: this._width,
                        h: this._fH,
                        src: this.getImg(this._data.coverUrl, this._width, this._fH).src
                    }
                },
                BackgroundFull: {
                    Img: {
                        w: this._fW,
                        h: this._fH,
                        src: this.getImg(this.getFullCover(), this._fW, this._fH).src
                    }
                }
            });
        }
    }

    _inactive() {
        this.patch({
            Background: {
                Img: undefined
            }
        });
        if (Storage.get(appPlatformKey) !== PLATFORMS.posttv) {
            this.patch({
                BackgroundFocused: {
                    Img: undefined
                },
                BackgroundFull: {
                    Img: undefined
                }
            });
        }
        // this.stage.gc();
    }

    _attach() {
        ["categoryFocus", "categoryBlur"].forEach(event => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detach() {
        ["categoryFocus", "categoryBlur"].forEach(event => {
            this.application.off(event, this.listeners[event]);
        });
    }

    _triggerResize(skip, isFocus) {
        if (skip) return;

        if (isFocus) {
            this._resize(isFocus);
        } else {
            Registry.setTimeout(() => {
                this._resize(isFocus);
            }, 1);
        }
    }

    _resize(isFocus) {
        if (Storage.get(appPlatformKey) !== PLATFORMS.posttv) {
            const activeHeight = isFocus ? this._fH : this._height;

            this.patch({
                h: activeHeight,
                w: this._width,
                Background: {
                    alpha: !isFocus ? 1 : 0.0001
                },
                BackgroundFocused: {
                    alpha: isFocus ? 1 : 0.0001
                }
            });
        }
    }

    _handleEnter() {
        this.fireAncestors("$onMovieSelect", { data: this._data, continueWatching: this._isRecentlyWatched });
    }

    _handleClick() {
        if (this.hasFocus()) {
            this._handleEnter();
        } else {
            this.parent.parent.selectedIndex = this._index;
            this.fireAncestors("$movieClicked");
            return true;
        }
    }

    _focus() {
        // TODO: animation doesnt look good on vertical view
        // clean up here :/

        if (Storage.get(appPlatformKey) !== PLATFORMS.posttv) {
            this.patch({
                y: 0,
                h: this._fH,
                w: this._fW,
                Background: {
                    alpha: 0.01
                },
                BackgroundFull: {
                    alpha: 1,
                    smooth: {
                        // w:  [this._fW, { duration: 0.5, timingFunction: "linear" }] }
                        w: this._fW
                    }
                }
            });
        } else {
            this.patch({
                Border: {
                    smooth: {
                        alpha: 1
                    }
                }
            });
        }

        if (this._vertical) {
            this.patch({
                Label: {
                    smooth: {
                        alpha: [1, { duration: 0.4, timingFunction: "linear" }],
                        w: this._fW,
                        // alpha: 1,
                        color: Colors("focus").get()
                    }
                }
            });
        } else {
            this.patch({
                EpisodeNumber: {
                    smooth: {
                        alpha: 0
                    }
                },
                Label: {
                    smooth: {
                        w: this._fW,
                        alpha: 1,
                        color: Colors("focus").get()
                    }
                }
            });
        }

        if (Storage.get(appPlatformKey) !== PLATFORMS.posttv) {
            this.fireAncestors("$itemChanged");
        }
    }

    _unfocus() {
        if (Storage.get(appPlatformKey) !== PLATFORMS.posttv) {
            if (this._vertical) {
                this.patch({
                    y: this._vertical ? (this._fH - this._height) / 2 : 0
                });
            }

            this.patch({
                h: this._vertical ? this._height : this._fH,
                w: this._width,
                BackgroundFull: {
                    alpha: 0.01,
                    smooth: {
                        w: this._width
                    }
                }
            });
        } else {
            this.patch({
                Border: {
                    smooth: {
                        alpha: 0
                    }
                }
            });
        }

        if (this._vertical) {
            this.patch({
                Background: {
                    alpha: 1
                },
                Label: {
                    smooth: {
                        w: this._width,
                        alpha: [0, { duration: 0.4, timingFunction: "linear" }],
                        // alpha: 0,
                        color: Colors("focus")
                            .alpha(0)
                            .get()
                    }
                }
            });
        } else {
            this.patch({
                Background: {
                    alpha: 1
                },
                EpisodeNumber: {
                    smooth: {
                        alpha: 1
                    }
                },
                Label: {
                    smooth: {
                        w: this._width,
                        alpha: 0,
                        color: Colors("focus")
                            .alpha(0)
                            .get()
                    }
                }
            });
        }
        if (Storage.get(appPlatformKey) !== PLATFORMS.posttv) {
            this.fireAncestors("$itemChanged");
        }
    }

    getFullCover() {
        return this._vertical ? this._data.coverUrl : this._data.backdropUrl;
    }

    getImg(src, width, height) {
        return Img(`${src}?aspect_ratio=${width}:${height}&width=${width / 2}&height=${height / 2}`).cover(
            width,
            height
        );
    }

    _adjustLabel() {
        const isPosttv = Storage.get(appPlatformKey) === PLATFORMS.posttv;

        const offset = isPosttv ? 24 : 48;
        const iconSize = isPosttv ? 16 : 24;

        const textWidth = this._fW - offset * 2 - iconSize * 2;

        this.patch({
            Label: {
                w: this._width,
                Categories: {
                    x: offset,
                    text: {
                        wordWrapWidth: textWidth,
                        wordWrap: true
                    }
                },
                Icon: {
                    flexItem: { marginLeft: iconSize, marginRight: offset },
                    w: iconSize,
                    h: iconSize
                }
            }
        });
    }

    _makeBorder() {
        this.patch({
            Border: {
                x: -1,
                y: -1,
                w: this._fW + 2,
                h: this._fH + 1,
                alpha: 0,
                texture: Lightning.Tools.getRoundRect(this._fW + 2, this._fH + 1, 0, 3, Colors("focus").get(), false)
            }
        });
    }
}
