import { Lightning, Router } from "@lightningjs/sdk";
import { ModalWindow } from "../../components";
import Exit from "../../components/Exit/Exit";

export default class ExitPopup extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            Modal: {
                type: ModalWindow
            }
        };
    }

    _getFocused() {
        return this.tag("Modal");
    }

    _handleClick() {}

    async open(resolve, reject) {
        this._resolve = resolve;
        this._reject = reject;
        this.tag("Modal").open([
            {
                type: Exit
            }
        ]);
    }

    $closeExit() {
        this._reject();
        Router.focusPage();
    }

    $appExit() {
        this._resolve();
    }
}
