import { Key as LightningKey } from "@lightningjs/ui";

export const KEY_DIMENSIONS = { h: 68, w: 77.2, padding: 0, fixed: true };
export default class ClickableKey extends LightningKey {
    _handleClick(e) {
        // TODO: extend keyboard comp instead and fire event
        const key = this.parent?._key?.data?.key || "";

        this.parent.keyboard._handleKey({
            key: key,
            code: key
        });

        this.parent.keyboard._refocus();
    }
}
