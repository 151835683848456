import { Lightning } from "@lightningjs/sdk";
import Button from "../Button/Button";

export default class ContinueWatchingButton extends Lightning.Component {
    static _template() {
        return {
            type: Button,
            w: 327,
            h: 84,
            Content: {
                type: Button,
                w: 327,
                h: 84,
                label: "continueWatching",
                activeIcon: "icons/go-active.png",
                inactiveIcon: "icons/go.png"
            }
        };
    }

    set data(value) {
        this._data = value;
    }

    _getFocused() {
        return this.tag("Content");
    }

    _handleEnter() {
        this.fireAncestors("$onMovieSelect", { data: this._data, parent: this._data.parent, continueWatching: true });
    }
}
