import { Language, Lightning, Router } from "@lightningjs/sdk";
import Button from "../../Button/Button";
import { logout } from "../../../lib/api";
import { storageRemoveUser } from "../../../lib/storage";
import TranslatableText from "../../TranslatableText/TranslatableText";

export default class LogoutPopup extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            w: 584,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            flexItem: { grow: 1 },
            Label: {
                flexItem: { marginBottom: 48 },
                type: TranslatableText,
                key: "subscription",
                text: {
                    fontSize: 32
                }
            },
            Info: {
                flexItem: { marginBottom: 48 },
                type: TranslatableText,
                key: "confirmLogout",
                text: {
                    fontSize: 24,
                    lineHeight: 36,
                    textAlign: "center"
                }
            },
            Error: {
                alpha: 1,
                text: {
                    fontSize: 24,
                    lineHeight: 36,
                    textAlign: "center"
                }
            },
            Actions: {
                collision: true,
                w: 584,
                h: 96,
                flex: {
                    direction: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between"
                },
                Cancel: {
                    collision: true,
                    zIndex: 92,
                    type: Button,
                    w: 280,
                    h: 96,
                    x: 0,
                    label: "no",
                    flexItem: { minWidth: 280 },
                    signals: {
                        handleClick: "_handleClose"
                    }
                },
                Confirm: {
                    collision: true,
                    zIndex: 92,
                    type: Button,
                    w: 280,
                    h: 96,
                    label: "yes",
                    flexItem: { minWidth: 280 },
                    signals: {
                        handleClick: "_handleConfirm"
                    }
                }
            }
        };
    }

    set username(value) {
        this.patch({
            Label: {
                text: {
                    text: value
                }
            }
        });
    }

    _active() {
        this._setError(null);
        this._setState("ConfirmState");
    }

    _setError(msg) {
        this.patch({
            Error: {
                text: msg || "",
                alpha: msg ? 1 : 0,
                flexItem: { marginBottom: msg ? 48 : 0 }
            }
        });
    }

    _handleClose() {
        Router.focusPage();
    }

    async _handleConfirm() {
        this._setError(null);

        try {
            const res = await logout();
            if (res.success || (res.data && res.data.success)) {
                storageRemoveUser();

                this.application.emit("userAuth", false);

                Router.focusPage();
            } else {
                this._setError(res.message);
            }
        } catch (e) {
            this._setError(Language.translate("unexpectedError"));
        }
    }

    static _states() {
        return [
            class CancelState extends this {
                _getFocused() {
                    return this.tag("Cancel");
                }

                _handleRight() {
                    this._setState("ConfirmState");
                }

                _handleEnter() {
                    this._handleClose();
                }
            },
            class ConfirmState extends this {
                _getFocused() {
                    return this.tag("Confirm");
                }

                _handleLeft() {
                    this._setState("CancelState");
                }

                async _handleEnter() {
                    await this._handleConfirm();
                }
            }
        ];
    }
}
