import { Lightning, Router } from "@lightningjs/sdk";
import Button from "../Button/Button";
import { favoriteMovie } from "../../lib/api";
import { storageGetUser } from "../../lib/storage";

export default class FavoriteButton extends Lightning.Component {
    static _template() {
        return {
            w: 223,
            h: 84,
            collision: true,
            Content: {
                type: Button,
                w: 223,
                h: 84,
                activeIcon: "icons/favorite-add-active.png",
                inactiveIcon: "icons/favorite-add-inactive.png",
                label: "favorite",
                hasBackground: true,
                signals: {
                    handleClick: "_handleEnter"
                }
            }
        };
    }

    set widgets(value) {
        this._widgets = value;
    }

    set id(value) {
        this._id = value;
    }

    set isFavorite(value) {
        this._isFavorite = value;

        this.patch({
            Content: {
                ...this.getIcons()
            }
        });
    }

    _getFocused() {
        return this.tag("Content");
    }

    async _handleEnter() {
        // click
        if (!this.hasFocus()) {
            this.signal("handleClick");
        }

        const user = storageGetUser();

        if (!user) {
            if (this._widgets.authpopup) {
                this._widgets.authpopup.open();
                Router.focusWidget("AuthPopup");
            }
        } else {
            const data = await favoriteMovie(this._id, this._isFavorite);
            if (data.success) {
                this._isFavorite = !this._isFavorite;
                this.patch({
                    Content: {
                        ...this.getIcons()
                    }
                });
            } else {
                //TODO: show error?
            }
        }
    }

    getIcons() {
        return {
            activeIcon: this._isFavorite ? "icons/favorite-added-active.png" : "icons/favorite-add-active.png",
            inactiveIcon: this._isFavorite ? "icons/favorite-added-inactive.png" : "icons/favorite-add-inactive.png"
        };
    }
}
