import { Colors, Lightning, Registry, Router, Utils, Storage } from "@lightningjs/sdk";
import { LoadingCircle, MovieList } from "../components";
import { KEYBOARD_FORMATS } from "../components/Keyboard/Keyboard";
import { searchMovies } from "../lib/api";
import { MENU_PAGE_IDS, pageTransition, POSTER_HORIZONTAL_DIMENSIONS } from "../lib/utils";
import { handleMovieSelect, PLATFORMS } from "../lib/helpers";
import TranslatableText from "../components/TranslatableText/TranslatableText";
import { appPlatformKey } from "../lib/storage";
import { InputField, Keyboard } from "@lightningjs/ui";
import IconKey from "../components/Keyboard/IconKey";
import ClickableKey from "../components/Keyboard/ClickableKey";

const keyConfig = {
    h: 68,
    w: 77.2
};

export default class Search extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            rect: true,
            w: 1920,
            h: 1080,
            color: Colors("pageBackground").get(),
            Page: {
                y: 64,
                x: 112,
                w: 1920,
                h: 1080,
                collision: true,
                Input: {
                    rect: true,
                    color: Colors("backgroundContrast").get(),
                    w: 386,
                    h: 58,
                    flex: {
                        alignItems: "center"
                    },
                    Icon: {
                        h: 19,
                        w: 18,
                        flexItem: { marginLeft: 20 },
                        src: Utils.asset("icons/search.png")
                    },
                    InputWrapper: {
                        flexItem: { marginLeft: 20 },
                        w: 318,
                        h: (h) => h,
                        clipping: true,
                        InputField: {
                            mountY: 0.5,
                            y: (h) => h / 2,
                            type: InputField,
                            inputText: {
                                text: "",
                                fontSize: 24
                            },
                            cursor: {
                                h: 0,
                                w: 0
                            },
                            maxLabelWidth: 318,
                            labelPositionStatic: false
                        }
                    }
                },
                Keyboard: {
                    y: 85,
                    w: 360,
                    type: Keyboard,
                    formats: KEYBOARD_FORMATS,
                    spacing: 1,
                    rowWrap: false,
                    collision: true,
                    config: {
                        buttonTypes: {
                            default: {
                                type: ClickableKey,
                                ...keyConfig,
                                backgroundColors: {
                                    unfocused: Colors("black").get(),
                                    focused: Colors("black").get()
                                },
                                labelColors: {
                                    unfocused: Colors("white").get(),
                                    focused: Colors("focus").get()
                                },
                                label: {
                                    text: {
                                        fontSize: 32,
                                        fontStyle: 600
                                    }
                                }
                            },
                            Backspace: {
                                type: IconKey,
                                h: keyConfig.h,
                                w: keyConfig.w * 2,
                                alignLeft: true,
                                icon: "icons/delete"
                            },
                            Space: {
                                type: IconKey,
                                h: keyConfig.h,
                                w: keyConfig.w,
                                icon: "icons/space"
                            },
                            Layout: {
                                type: ClickableKey,
                                ...keyConfig,
                                backgroundColors: {
                                    unfocused: Colors("black").get(),
                                    focused: Colors("black").get()
                                },
                                labelColors: {
                                    unfocused: Colors("white").get(),
                                    focused: Colors("focus").get()
                                },
                                label: {
                                    text: {
                                        fontSize: 32,
                                        fontStyle: 600
                                    }
                                }
                            }
                        },
                        layouts: {
                            abc: [
                                ["a", "b", "c", "d", "e"],
                                ["f", "g", "h", "i", "j"],
                                ["k", "l", "m", "n", "o"],
                                ["p", "q", "r", "s", "t"],
                                ["u", "v", "w", "x", "y"],
                                ["z", "Backspace", "Space", "Layout:123"]
                            ],
                            123: [
                                ["1", "2", "3", "4", "5"],
                                ["6", "7", "8", "9", "0"],
                                ["Backspace", "Space", "Layout:abc"]
                            ]
                        }
                    },
                    currentLayout: "abc",
                    signals: { onInputChanged: "_onSoftKey", onLayout: "_handleLayoutChange" }
                },
                ResultList: {
                    collision: true,
                    type: MovieList,
                    w: Storage.get(appPlatformKey) === PLATFORMS.posttv ? 1300 : 1600,
                    h: 500,
                    x: 550 - 112,
                    alpha: 0
                },
                NoResults: {
                    w: 1306,
                    x: 550 - 112,
                    h: 500,
                    alpha: 0,
                    flex: {
                        alignItems: "center",
                        justifyContent: "center"
                    },
                    Text: {
                        type: TranslatableText,
                        key: "noSearchResults"
                    }
                },
                Loader: {
                    w: 1306,
                    x: 550 - 112,
                    h: 500,
                    alpha: 0,
                    flex: {
                        alignItems: "center",
                        justifyContent: "center"
                    },
                    LoadingCircle: {
                        type: LoadingCircle,
                        isAbsolute: false
                    }
                },
                RecentTitle: {
                    y: 604 - 64,
                    type: TranslatableText,
                    text: {
                        fontSize: 24,
                        fontStyle: 500
                    }
                },
                RecentList: {
                    type: MovieList,
                    collision: true,
                    isRecentSearchList: true,
                    y: 648 - 64,
                    w: 1808,
                    h: 400
                }
            }
        };
    }

    historyState(params) {
        if (params) {
            this._setInputValue(params.query);
            console.log("show on params");
            this.tag("ResultList").patch({
                alpha: 1
            });
        } else {
            return {
                query: this._value
            };
        }
    }

    _getFocused() {
        return this.tag("Keyboard");
    }

    _handleLeft() {
        Router.focusWidget("Menu");
    }

    _handleDown() {
        if (this.recent && this.recent.length > 0) {
            this._setState("RecentState");
        }
    }

    _handleRight() {
        if (this.data && this.data.length > 0) {
            this._setState("ResultListState");
        }
    }

    _setCollision(element) {
        for (const child of element.children) {
            child?.patch({
                collision: true
            });

            if (child?.children) {
                this._setCollision(child);
            }
        }
    }

    _handleLayoutChange() {
        setTimeout(() => {
            this._setCollision(this.tag("Keyboard"));
        }, 20);
    }

    _init() {
        setTimeout(() => {
            this._setCollision(this.tag("Keyboard"));
        }, 20);
    }

    _active() {
        this.widgets.menu.setActive(MENU_PAGE_IDS.search);

        this.recent = this.getRecent();

        if (this.recent.length > 0) {
            this.renderRecent();
        }
    }

    _inactive() {
        this._setInputValue("");

        if (this._timeout) {
            Registry.clearTimeout(this._timeout);
            this._timeout = null;
        }

        if (this.searching) this.abortController.abort();
        this.searching = false;

        this.hideNoResults(false);

        console.log("hide list");

        this.patch({
            Page: {
                ResultList: {
                    alpha: 0
                }
            }
        });
    }

    _handleBack() {
        Router.navigate("home");
    }

    $movieClicked({ isRecentSearchList }) {
        if (isRecentSearchList) {
            this._setState("RecentState");
        } else {
            this._setState("ResultListState");
        }
    }

    renderRecent() {
        this.patch({
            Page: {
                RecentTitle: {
                    key: "recentSearches"
                },
                RecentList: {
                    h: 400,
                    dimensions:
                        Storage.get(appPlatformKey) === PLATFORMS.posttv
                            ? POSTER_HORIZONTAL_DIMENSIONS.equalS
                            : POSTER_HORIZONTAL_DIMENSIONS.m,
                    data: this.recent
                }
            }
        });
    }

    renderResults() {
        if (this.data && this.data.length) {
            this.hideNoResults();
        } else {
            if (this._value) {
                this.showNoResults();
                return;
            }
        }

        this.patch({
            Page: {
                ResultList: {
                    selectedIndex: 0,
                    h: 500,
                    dimensions:
                        Storage.get(appPlatformKey) === PLATFORMS.posttv
                            ? POSTER_HORIZONTAL_DIMENSIONS.equalXxl
                            : POSTER_HORIZONTAL_DIMENSIONS.xl,
                    data: this.data
                }
            }
        });
    }

    showLoader() {
        this.patch({
            Page: {
                NoResults: {
                    smooth: {
                        alpha: 0
                    }
                },
                Loader: {
                    smooth: {
                        alpha: 1
                    }
                },
                ResultList: {
                    smooth: {
                        alpha: 0
                    }
                }
            }
        });
    }

    showNoResults() {
        this.patch({
            Page: {
                NoResults: {
                    smooth: {
                        alpha: 1
                    }
                },
                Loader: {
                    smooth: {
                        alpha: 0
                    }
                },
                ResultList: {
                    smooth: {
                        alpha: 0
                    }
                }
            }
        });
    }

    hideNoResults(showList = true) {
        this.patch({
            Page: {
                NoResults: {
                    smooth: {
                        alpha: 0
                    }
                },
                Loader: {
                    smooth: {
                        alpha: 0
                    }
                }
            }
        });

        if (showList) {
            this.patch({
                Page: {
                    ResultList: {
                        smooth: {
                            alpha: 1
                        }
                    }
                }
            });
        }
    }

    _setInputValue(value) {
        this._value = value;
        this.tag("InputField").onInputChanged({ input: this._value });
    }

    _onSoftKey({ input }) {
        this._setState("KeyboardState");

        if (!this._value) this._value = "";

        this._value = input;

        this._setInputValue(this._value);

        this.triggerSearch();
    }

    $onMovieSelect(event) {
        const movie = event.data;

        const limit = 16;
        let items = this.getRecent();
        if (items.find((item) => item.movieId === movie.movieId)) {
            items = items.filter((item) => item.movieId !== movie.movieId);
        } else {
            if (items.length >= limit) items.pop();
        }

        items.unshift(movie);

        Storage.set("recentSearch", JSON.stringify(items));

        handleMovieSelect(this.widgets, event);
    }

    getRecent() {
        const items = Storage.get("recentSearch");
        return items ? JSON.parse(items) : [];
    }

    triggerSearch() {
        if (!this.data || (this.data && !this.data.length)) {
            if (this._value) {
                this.showLoader();
            } else {
                this.hideNoResults();
            }
        }

        if (this._timeout) {
            Registry.clearTimeout(this._timeout);
            this._timeout = null;
        }

        if (this.searching && this.abortController) this.abortController.abort();

        this.abortController = new AbortController();

        const _this = this;
        this._timeout = Registry.setTimeout(() => {
            _this.search(_this);
            this._timeout = null;
        }, 600);
    }

    async search(_this) {
        try {
            _this.searching = true;

            _this.data = await searchMovies(_this._value, _this.abortController.signal);

            _this.searching = false;

            _this.renderResults();
        } catch (e) {
            console.log("e", e);

            this.showNoResults();
        }
    }

    pageTransition(pageIn, pageOut) {
        return pageTransition(pageIn, pageOut);
    }

    static _states() {
        return [
            class ResultListState extends this {
                _getFocused() {
                    return this.tag("ResultList");
                }

                _handleLeft() {
                    this._setState("KeyboardState");
                }
            },
            class KeyboardState extends this {
                _getFocused() {
                    return this.tag("Keyboard");
                }
            },
            class RecentState extends this {
                _getFocused() {
                    return this.tag("RecentList");
                }

                _handleUp() {
                    this._setState("Keyboard");
                }

                _handleRight() {}
            }
        ];
    }
}
