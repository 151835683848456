import { Lightning, Router } from "@lightningjs/sdk";
import Button from "../Button/Button";
import TranslatableText from "../TranslatableText/TranslatableText";
import { Carousel } from "@lightningjs/ui";
import ModalSelectorItem from "./components/ModalSelectorItem";

export default class ModalSelector extends Lightning.Component {
    static _template() {
        return {
            w: 584,
            collision: true,
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            flexItem: { grow: 1 },
            Label: {
                h: 80,
                type: TranslatableText,
                text: {
                    fontSize: 32
                }
            },
            List: {
                type: Carousel,
                w: 584,
                clipping: true,
                collision: true,
                direction: "column"
            },
            Actions: {
                collision: true,
                w: 584,
                h: 96,
                flexItem: {
                    marginTop: 48
                },
                flex: {
                    direction: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between"
                },
                Back: {
                    type: Button,
                    w: 280,
                    h: 96,
                    x: 0,
                    zIndex: 92,
                    label: "back",
                    flexItem: { minWidth: 280 },
                    signals: {
                        handleClick: "_handleBackClick"
                    }
                },
                Done: {
                    type: Button,
                    w: 280,
                    h: 96,
                    zIndex: 92,
                    label: "done",
                    flexItem: { minWidth: 280 },
                    signals: {
                        handleClick: "_handleDoneClick"
                    }
                }
            }
        };
    }

    _construct() {
        this._itemHeight = 60;
        this._itemSpacing = 28;

        this._maxHeight = this._calcHeight(6);
    }

    set eventEmit(value) {
        this._eventEmit = value;
    }

    set handleModalSave(func) {
        this._handleModalSave = func;
    }

    set label(value) {
        this.patch({
            Label: {
                key: value
            }
        });
    }

    set defaultId(value) {
        this._defaultId = value;
    }

    set items(value) {
        this._items = value;

        const total = this._items.length;

        this.patch({
            List: {
                h: Math.min(this._calcHeight(total), this._maxHeight),
                spacing: this._itemSpacing
            }
        });

        this._selectedId = this._defaultId;

        this._renderItems();

        this.tag("List").setIndex(this._items.findIndex(item => item.id === this._selectedId));

        for (const wrapper of this.tag("List").children) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    _init() {
        this.listeners = {
            modalItemSelected: event => {
                this._modalItemSelected(event);
            }
        };
    }

    _attach() {
        ["modalItemSelected"].forEach(event => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detach() {
        ["modalItemSelected"].forEach(event => {
            this.application.off(event, this.listeners[event]);
        });
    }

    _modalItemSelected({ id, clicked }) {
        this._selectedId = id;

        if (!clicked) {
            this._setState("DoneState");
        }
    }

    _calcHeight(total) {
        return this._itemHeight * total + this._itemSpacing * total - 1;
    }

    _getFocused() {
        return this.tag("List");
    }

    _active() {
        this._setState("SelectorState");
    }

    _renderItems() {
        this.patch({
            List: {
                items: this._items.map(item => {
                    return {
                        type: ModalSelectorItem,
                        w: 584,
                        h: this._itemHeight,
                        selected: this._selectedId === item.id,
                        item
                    };
                })
            }
        });
    }

    _handleBackClick() {
        Router.focusPage();
    }

    _handleDoneClick() {
        this.application.emit("modalItemSaved", this._selectedId);

        Router.focusPage();
    }

    static _states() {
        return [
            class SelectorState extends this {
                _getFocused() {
                    return this.tag("List");
                }

                _handleBack() {
                    this._selectItem();

                    this._setState("BackState");
                }

                // prevent exit
                _handleUp() {}

                _handleDown() {}

                _selectItem() {}
            },
            class BackState extends this {
                _getFocused() {
                    return this.tag("Back");
                }

                _handleUp() {
                    this._setState("SelectorState");
                }

                _handleRight() {
                    this._setState("DoneState");
                }

                _handleEnter() {
                    this._handleBackClick();
                }
            },
            class DoneState extends this {
                _getFocused() {
                    return this.tag("Done");
                }

                _handleUp() {
                    this._setState("SelectorState");
                }

                _handleLeft() {
                    this._setState("BackState");
                }

                _handleDown() {}

                _handleEnter() {
                    this._handleDoneClick();
                }
            }
        ];
    }
}
