import { Colors, Lightning } from "@lightningjs/sdk";

const barWidth = 1622;
export default class Progress extends Lightning.Component {
    static _template() {
        return {
            w: 1920,
            h: 36,
            y: 28,
            flex: {
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row"
            },
            CurrentTime: {
                flexItem: { marginLeft: 48 },
                w: 80,
                h: 34,
                text: {
                    fontSize: 26,
                    fontStyle: 500
                }
            },
            Bar: {
                flexItem: {},
                w: barWidth,
                h: 20,
                Line: {
                    y: 9,
                    x: 0,
                    w: barWidth,
                    h: 2,
                    rect: true,
                    color: Colors("white").get()
                },
                Progress: {
                    x: 0,
                    y: 7,
                    h: 6,
                    w: 0,
                    rect: true,
                    color: Colors("focus").get()
                },
                ProgressPoint: {
                    y: 0,
                    h: 20,
                    w: 20,
                    rect: true,
                    color: Colors("focus").get(),
                    rotation: Math.PI / 4
                }
            },
            Duration: {
                flexItem: { marginRight: 48 },
                w: 80,
                h: 34,
                text: {
                    fontSize: 26,
                    fontStyle: 500,
                    textAlign: "right"
                }
            }
        };
    }

    static get barWidth() {
        return 1622;
    }

    set duration(value) {
        this._duration = value;
        this.patch({
            Duration: {
                text: {
                    text: this.timeFormat(value)
                }
            }
        });
    }

    set currentTime(value) {
        value = value || 0;
        const percent = !value || !this._duration ? 0 : (value / this._duration) * 100;
        const width = !percent ? 0 : (percent / 100) * barWidth;

        this.patch({
            CurrentTime: {
                text: {
                    text: this.timeFormat(value)
                }
            },
            Bar: {
                Progress: {
                    w: Math.min(width, barWidth)
                },
                ProgressPoint: {
                    x: Math.min(width, barWidth)
                }
            }
        });
    }

    timeFormat(value) {
        if (!value || value === 0 || isNaN(parseInt(value))) return "00:00";

        if (value > 60 * 60) {
            return (
                Math.floor(value / 3600) +
                ":" +
                ("0" + Math.floor((value / 60) % 60)).slice(-2) +
                ":" +
                ("0" + Math.floor(value % 60)).slice(-2)
            );
        }
        return ("0" + Math.floor((value / 60) % 60)).slice(-2) + ":" + ("0" + Math.floor(value % 60)).slice(-2);
    }
}
