import { Colors, Lightning, Router } from "@lightningjs/sdk";
import { ErrorModal } from "../components";
import { pageTransition } from "../lib/utils";

export default class Error extends Lightning.Component {
    static _template() {
        return {
            w: 1920,
            h: 1080,
            rect: true,
            color: Colors("black").get(),
            flex: {
                direction: "column",
                alignItems: "center",
                justifyContent: "center"
            },
            Modal: {
                flexItem: {},
                flex: { padding: 48 },
                rect: true,
                color: Colors("background").get(),
                Error: {
                    type: ErrorModal
                }
            }
        };
    }

    _getFocused() {
        return this.tag("Error");
    }

    _handleEnter() {
        Router.navigate("home");
    }

    pageTransition(pageIn) {
        return pageTransition(pageIn);
    }
}
