import { Colors, Lightning } from "@lightningjs/sdk";
import TranslatableText from "../../TranslatableText/TranslatableText";

export default class ModalSelectorItem extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            zIndex: 92,
            h: 60,
            w: 584,
            Box: {
                rect: true,
                color: Colors("background")
                    .alpha(0)
                    .get(),
                h: 60,
                w: 584,
                Label: {
                    w: 584,
                    y: 16,
                    type: TranslatableText,
                    text: {
                        fontSize: 24,
                        fontStyle: 500,
                        textAlign: "center"
                    }
                }
            }
        };
    }

    get item() {
        return this._item;
    }

    // expected id, ( label | key | translate:{} )
    set item(value) {
        this._item = value;
        if (this._item.key) {
            this.patch({
                Box: {
                    Label: {
                        translate: {
                            key: "seasonNumber",
                            value: value.seasonNumber
                        }
                    }
                }
            });
        } else if (this._item.translate) {
            this.patch({
                Box: {
                    Label: {
                        translate: this._item.translate
                    }
                }
            });
        } else {
            this.patch({
                Box: {
                    Label: {
                        text: {
                            text: this._item.label
                        }
                    }
                }
            });
        }
    }

    set selected(isSelected) {
        this._isSelected = isSelected;
        if (isSelected) {
            this._select();
        }
    }

    _init() {
        this.listeners = {
            modalItemSelected: event => {
                this._modalItemSelected(event);
            }
        };
    }

    _attach() {
        ["modalItemSelected"].forEach(event => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detach() {
        ["modalItemSelected"].forEach(event => {
            this.application.off(event, this.listeners[event]);
        });
    }

    _modalItemSelected(id) {
        this._isSelected = id === this._item.id;
        if (this._isSelected) {
            this._select();
        } else {
            this._deselect();
        }
    }

    _focus() {
        this.patch({
            Box: {
                smooth: {
                    color: Colors("focus").get()
                },
                Label: {
                    text: {
                        textColor: Colors("black").get()
                    }
                }
            }
        });
    }

    _unfocus() {
        if (this._isSelected) {
            this._select();
        } else {
            this._deselect();
        }
    }

    _handleEnter() {
        this.application.emit("modalItemSelected", {
            id: this._item.id,
            clicked: false
        });
    }

    _handleClick() {
        this.collectionWrapper.setIndex(this.parent.componentIndex);

        this.application.emit("modalItemSelected", {
            id: this._item.id,
            clicked: true
        });

        // this._handleEnter();
    }

    _deselect() {
        this.patch({
            Box: {
                smooth: {
                    color: Colors("background")
                        .alpha(0)
                        .get()
                },
                Label: {
                    text: {
                        textColor: Colors("white").get()
                    }
                }
            }
        });
    }

    _select() {
        this.patch({
            Box: {
                smooth: {
                    color: Colors("focus")
                        .alpha(0.1)
                        .get()
                },
                Label: {
                    text: {
                        textColor: Colors("focus").get()
                    }
                }
            }
        });
    }
}
