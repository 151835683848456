import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import TranslatableText from "../TranslatableText/TranslatableText";

export default class Button extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            Background: {
                rect: true,
                color: Colors("focus").alpha(0).get(),
                y: 2,
                x: 2
            },
            Label: {
                // mount: 0.5,
                // x: w => w / 2,
                // y: h => h / 2,
                flex: {
                    justifyContent: "center",
                    alignItems: "center",
                    direction: "row"
                },
                PrevIcon: {
                    Active: { alpha: 0.01 }
                },
                Text: {
                    y: 2,
                    flexItem: {},
                    type: TranslatableText,
                    text: {
                        fontSize: 24,
                        verticalAlign: "center",
                        fontStyle: 500,
                        textColor: Colors("white").get()
                    }
                },
                Icon: {
                    Active: { alpha: 0.01 }
                }
            }
        };
    }

    _construct() {
        this._useBorder = true;
    }

    set hasBackground(value) {
        this._hasBackground = value;
    }

    set useBorder(value) {
        this._useBorder = value;
    }

    set h(value) {
        this._height = value;
        this.patch({
            Label: {
                // y: value / 2
                h: value
            }
        });
    }

    set w(value) {
        this._width = value;
        this.patch({
            Label: {
                w: value
            }
        });
    }

    set text(value) {
        this._text = value;
        this.patch({
            Label: {
                Text: {
                    text: this._text
                }
            }
        });
    }

    set label(value) {
        this._label = value;
        this.patch({
            Label: {
                Text: {
                    key: this._label
                }
            }
        });
    }

    set translate(value) {
        this._translate = value;
        this.patch({
            Label: {
                Text: {
                    translate: this._translate
                }
            }
        });
    }

    set prevIcon(value) {
        this._prevIcon = value;

        const prevActive = value.replace(".", "-active.");

        this.patch({
            Label: {
                PrevIcon: {
                    w: 24,
                    h: 24,
                    flexItem: {
                        marginRight: 12
                    },
                    Inactive: {
                        w: 24,
                        h: 24,
                        src: Utils.asset(value)
                    },
                    Active: { w: 24, h: 24, src: Utils.asset(prevActive) }
                }
            }
        });
    }

    set activeIcon(value) {
        this._activeIcon = value;

        this.patch({
            Label: {
                Icon: {
                    w: 24,
                    h: 24,
                    flexItem: {
                        marginLeft: 12
                    },
                    Active: { w: 24, h: 24, src: Utils.asset(value) }
                }
            }
        });
    }

    set inactiveIcon(value) {
        this._inactiveIcon = value;

        this.patch({
            Label: {
                Icon: {
                    w: 24,
                    h: 24,
                    flexItem: {
                        marginLeft: 12
                    },
                    Inactive: {
                        w: 24,
                        h: 24,
                        src: Utils.asset(value)
                    }
                }
            }
        });
    }

    _init() {
        this._makeBorder(false);

        this.patch({
            w: this._width,
            h: this._height,
            Background: {
                w: this._width - 2,
                h: this._height - 2,

                color: Colors("black")
                    .alpha(this._hasBackground ? 0.8 : 0)
                    .get()
            }
        });
    }

    _handleClick() {
        this.signal("handleClick");
    }

    _focus() {
        this.patch({
            Background: {
                smooth: {
                    color: Colors("focus").get()
                }
            },
            Label: {
                Icon: {
                    Active: {
                        smooth: {
                            alpha: 1
                        }
                    },
                    Inactive: {
                        smooth: {
                            alpha: 0.01
                        }
                    }
                },
                PrevIcon: {
                    Active: {
                        smooth: {
                            alpha: 1
                        }
                    },
                    Inactive: {
                        smooth: {
                            alpha: 0.01
                        }
                    }
                },
                Text: {
                    text: {
                        textColor: Colors("background").get()
                    }
                }
            }
        });
        this._makeBorder(true);
    }

    _unfocus() {
        this._makeBorder(false);

        this.patch({
            Background: {
                smooth: {
                    color: Colors("black")
                        .alpha(this._hasBackground ? 0.8 : 0)
                        .get()
                }
            },
            Label: {
                Icon: {
                    Active: {
                        smooth: {
                            alpha: 0.01
                        }
                    },
                    Inactive: {
                        smooth: {
                            alpha: 1
                        }
                    }
                },
                PrevIcon: {
                    Active: {
                        smooth: {
                            alpha: 0.01
                        }
                    },
                    Inactive: {
                        smooth: {
                            alpha: 1
                        }
                    }
                },
                Text: {
                    text: {
                        textColor: Colors("white").get()
                    }
                }
            }
        });
    }

    _makeBorder(focused) {
        if (this._useBorder) {
            this.texture = Lightning.Tools.getRoundRect(
                this._width - 1,
                this._height - 1,
                0,
                1,
                Colors(focused ? "focus" : "white").get(),
                false
            );
        }
    }
}
