import { Colors, Lightning, Registry, Router } from "@lightningjs/sdk";

export default class ModalWindow extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            alpha: 0,
            w: 1920,
            h: 1080,
            y: 0,
            x: 0,
            zIndex: 90,
            Backdrop: {
                w: 1920,
                h: 1080,
                y: 0,
                x: 0,
                rect: true,
                collision: true,
                color: Colors("black")
                    .alpha(0.5)
                    .get(),
                flex: {
                    direction: "column",
                    alignItems: "center",
                    justifyContent: "center"
                },
                Modal: {
                    flexItem: {},
                    flex: { padding: 48 },
                    rect: true,
                    collision: true,
                    color: Colors("background").get(),
                    zIndex: 91
                }
            }
        };
    }

    _handleClick() {
        return true;
    }

    _construct() {
        this._allowedKeys = [
            "Backspace",
            "Enter",
            "ArrowDown",
            "ArrowLeft",
            "ArrowRight",
            "ArrowUp",
            "XF86Back",
            "Escape"
        ];
        this._allowedKeysIdentifiers = ["U+0008", "Enter", "Down", "Left", "Right", "Up"];
    }

    _getFocused() {
        return this.tag("Modal").children[0];
    }

    _captureKey({ key, code, keyCode, keyIdentifier }) {
        if (keyIdentifier) {
            return this._allowedKeysIdentifiers.indexOf(keyIdentifier) <= -1;
        } else {
            return this._allowedKeys.indexOf(key || code || keyCode) <= -1;
        }
    }

    _unfocus() {
        this.close();
    }

    $triggerCloseModal() {
        Registry.setTimeout(() => {
            Router.focusPage();
        }, 1);
    }

    open(children = [], props = {}) {
        try {
            this.tag("Modal").children = children;
        } catch (e) {
            console.log("e", e);
        }

        this.patch({
            Backdrop: {
                Modal: {
                    w: props.w || 584
                    // h: props.h || 432
                }
            },
            smooth: {
                alpha: 1
            }
        });

        this._refocus();
    }

    close() {
        this.fireAncestors("$modalClosed");
        this.application.emit("modalClosed", this._selectedId);

        this.patch({
            smooth: {
                alpha: 0
            }
        });
    }
}
