import { Lightning, Utils } from "@lightningjs/sdk";

const sizes = {
    playH: 35,
    playW: 24.5,
    pauseH: 31,
    pauseW: 24
};

export default class PlayPause extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            zIndex: 92,
            h: sizes.playH,
            w: sizes.playW,
            flex: { alignItems: "center" },
            Icon: {
                w: sizes.playW,
                h: sizes.playH,
                src: Utils.asset("icons/play.png")
            }
        };
    }

    static get playW() {
        return 24.5;
    }

    static get playH() {
        return 35;
    }

    static get pauseW() {
        return 24;
    }

    static get pauseH() {
        return 31;
    }

    set isPlaying(value) {
        this._isPlaying = value;
        this.updateIcon();
    }

    _focus() {
        this.focused = true;
        this.updateIcon();
    }

    _unfocus() {
        this.focused = false;
        this.updateIcon();
    }

    _handleEnter() {
        this.fireAncestors("$triggerPlayPause");
    }

    _handleClick() {
        this.signal("handleClick");
        this.fireAncestors("$triggerPlayPause");
    }

    updateIcon() {
        const prefix = this.focused ? "-active" : "";
        if (this._isPlaying) {
            this.patch({
                Icon: {
                    src: Utils.asset(`icons/pause${prefix}.png`),
                    w: sizes.pauseW,
                    h: sizes.pauseH
                }
            });
        } else {
            this.patch({
                Icon: {
                    w: sizes.playW,
                    h: sizes.playH,
                    src: Utils.asset(`icons/play${prefix}.png`)
                }
            });
        }
    }
}
