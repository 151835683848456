import { Lightning } from "@lightningjs/sdk";
import Button from "../Button/Button";

export default class SeasonSelector extends Lightning.Component {
    static _template() {
        return {
            Label: {
                w: 234,
                h: 84,
                type: Button,
                signals: {
                    handleClick: "_handleClick"
                }
            }
        };
    }

    set season(value) {
        this._season = value;
    }

    set totalSeasons(value) {
        if (value > 1) {
            this.patch({
                Label: {
                    translate: {
                        key: "seasonNumberOfTotal",
                        value: { currentSeason: this._season, totalSeasons: value }
                    }
                }
            });
        } else {
            this.patch({
                Label: {
                    translate: {
                        key: "seasonNumber",
                        value: this._season
                    }
                }
            });
        }
    }

    _getFocused() {
        return this.tag("Label");
    }

    _handleEnter() {
        this.fireAncestors("$onSeasonSelect");
    }

    _handleClick() {
        this.signal("onSeasonClick");
        this._handleEnter();
    }
}
