import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import TranslatableText from "../TranslatableText/TranslatableText";

export default class BackButton extends Lightning.Component {
    static _template() {
        return {
            w: 200,
            h: 50,
            collision: true,
            BackBtn: {
                Icon: {
                    Inactive: {
                        y: 4,
                        w: 24,
                        h: 24,
                        src: Utils.asset("icons/back.png")
                    },
                    Active: {
                        y: 4,
                        w: 24,
                        h: 24,
                        src: Utils.asset("icons/back-active.png"),
                        alpha: 0.01
                    }
                },
                Text: {
                    x: 30,
                    type: TranslatableText,
                    key: "back",
                    text: {
                        fontSize: 24,
                        fontStyle: 500
                    }
                }
            }
        };
    }

    set label(value) {
        this.patch({
            BackBtn: {
                Text: {
                    text: value
                }
            }
        });
    }

    _focus() {
        this.patch({
            BackBtn: {
                Icon: {
                    Inactive: {
                        alpha: 0.01
                    },
                    Active: {
                        alpha: 1
                    }
                },
                Text: {
                    text: {
                        textColor: Colors("focus").get()
                    }
                }
            }
        });
    }

    _unfocus() {
        this.patch({
            BackBtn: {
                Icon: {
                    Inactive: {
                        alpha: 1
                    },
                    Active: {
                        alpha: 0.01
                    }
                },
                Text: {
                    text: {
                        textColor: Colors("white").get()
                    }
                }
            }
        });
    }

    _handleClick() {
        this.signal("goBack");
    }
}
