import axios from "axios";
import camelcaseKeys from "./camelcaseKeys";
import { Language, Profile, Router, Storage } from "@lightningjs/sdk";

import { appPlatformKey, appPlatformTokenKey, storageGetUser } from "./storage";

import { v4 as uuidv4 } from "uuid";
import { PLATFORMS } from "./helpers";

export const urlPath = () => {
    // eslint-disable-next-line no-undef
    return process.env.APP_API_URL || "https://blackpills-api.alteox.app/api";
};

const userId = async () => {
    const user = storageGetUser();

    return user ? user.userId : "";
};

export const deviceToken = async () => {
    // zeasn or posttv
    const platformToken = Storage.get(appPlatformTokenKey);
    const platform = Storage.get(appPlatformKey);

    if (platform === PLATFORMS.amazon) {
        const id = await userId();
        Storage.set("uid", id);
        return id;
    }

    const savedId = Storage.get("uid");

    if ((!platformToken && savedId) || (platformToken && platformToken === savedId)) return savedId;

    let uid = platformToken || (await Profile.uid());

    // local browser
    if (uid === "ee6723b8-7ab3-462c-8d93-dbf61227998e") {
        uid = uuidv4();
    }

    Storage.set("uid", uid);

    return uid;
};

const currentLang = () => {
    return Language.get();
};

export const getTrending = async () => {
    const { data } = await axios.get(`${urlPath()}/dashboard/trending`, {
        params: {
            token: await deviceToken(),
            userId: await userId(),
            lang: currentLang()
        }
    });

    return camelcaseKeys(data);
};

export const getCategories = async () => {
    const { data } = await axios.get(`${urlPath()}/dashboard`, {
        params: {
            token: await deviceToken(),
            userId: await userId(),
            lang: currentLang()
        }
    });

    return camelcaseKeys(data);
};

export const getMovies = async (id) => {
    const { data } = await axios.get(`${urlPath()}/movies/${id}`, {
        params: {
            token: await deviceToken(),
            userId: await userId(),
            lang: currentLang()
        }
    });

    return camelcaseKeys(data);
};

export const searchMovies = async (query, signal) => {
    const { data } = await axios.get(`${urlPath()}/movies`, {
        signal,
        params: {
            search: query
        }
    });

    return camelcaseKeys(data);
};

export const getMovie = async (id) => {
    const { data } = await axios.get(`${urlPath()}/movies/${id}`, {
        params: {
            playback: 1,
            token: await deviceToken(),
            userId: await userId(),
            platform: Storage.get(appPlatformKey),
            lang: currentLang()
        }
    });

    return camelcaseKeys(data);
};

export const saveMovieProgress = async (id, time) => {
    await axios.post(`${urlPath()}/movieprogress`, {
        movieId: id,
        time,
        userId: await userId(),
        token: await deviceToken()
    });
};

export const favoriteMovie = async (id, isFavorite) => {
    const { data } = await axios.get(`${urlPath()}/movies/${id}`, {
        params: {
            // isFavorite is current
            favorite: isFavorite ? 0 : 1,
            userId: await userId(),
            token: await deviceToken()
        }
    });

    return data;
};

export const getFavorites = async () => {
    const { data } = await axios.get(`${urlPath()}/movies/favorites`, {
        params: {
            token: await deviceToken(),
            userId: await userId()
        }
    });

    return camelcaseKeys(data);
};

export const getAppInfo = async () => {
    const { data } = await axios.get(`${urlPath()}/misc/app`, {});

    return data;
};

export const getSubscription = async () => {
    const { platform } = Router.getQueryStringParams();

    // window.dispatchEvent(
    //     new CustomEvent("debug", {
    //         detail: "getSubscription platform : " + platform
    //     })
    // );
    //
    // window.dispatchEvent(
    //     new CustomEvent("debug", {
    //         detail: "getSubscription userId : " + (await userId())
    //     })
    // );

    if (platform === PLATFORMS.amazon || platform === PLATFORMS.google) {
        const { data } = await axios.get(`${urlPath()}/external`, {
            params: {
                userId: await userId(),
                platform: Storage.get(appPlatformKey)
            }
        });

        return {
            success: data.success,
            userId: await userId(),
            canWatch: data.subscribed,
            from: data.from,
            to: data.to
        };
    } else {
        const { data } = await axios.get(`${urlPath()}/misc/details`, {
            params: {
                token: await deviceToken()
            }
        });

        return camelcaseKeys(data);
    }
};

export const getSubscriptionLink = async () => {
    const { data } = await axios.get(`${urlPath()}/misc/geturl`, {
        params: {
            token: await deviceToken(),
            platform: Storage.get(appPlatformKey)
        }
    });

    return camelcaseKeys(data);
};

export const logout = async () => {
    const { data } = await axios.get(`${urlPath()}/misc/logout`, {
        params: {
            token: await deviceToken()
        }
    });

    return camelcaseKeys(data);
};

export const login = async (code) => {
    const { data } = await axios.get(`${urlPath()}/misc/login`, {
        params: {
            token: await deviceToken(),
            code
        }
    });

    return camelcaseKeys(data);
};

export const activePosttvSubscription = async () => {
    const { data } = await axios.get(`${urlPath()}/posttv/activate`, {
        params: {
            token: await deviceToken()
        }
    });

    return camelcaseKeys(data);
};

// body : { userId, from, to, platform }
// TODO: v1 remove
export const activateAmazonSubscription = async (body) => {
    const { data } = await axios.post(
        `${urlPath()}/amazon`,
        {
            from: body.from,
            to: body.to
        },
        {
            params: {
                userId: body.userId
            }
        }
    );

    return camelcaseKeys(data);
};

export const syncExternalSubscription = async (body) => {
    console.log(
        "SEND DATA " +
            JSON.stringify({
                // amazon
                userId: body.userId,
                receiptId: body.receiptId,

                // google
                purchaseToken: body.purchaseToken,
                subscriptionId: body.subscriptionId,

                platform: Storage.get(appPlatformKey)
            })
    );

    const { data } = await axios.post(
        `${urlPath()}/external`,
        {
            // amazon
            userId: body.userId,
            receiptId: body.receiptId,

            // google
            purchaseToken: body.purchaseToken,
            subscriptionId: body.subscriptionId,

            platform: Storage.get(appPlatformKey)
        },
        {
            params: {
                userId: body.userId
            }
        }
    );

    return camelcaseKeys(data);
};

// body : { userId, to }
export const cancelAmazonSubscription = async (body) => {
    const { data } = await axios.post(
        `${urlPath()}/amazon`,
        {},
        {
            params: {
                userId: body.userId,
                to: body.to
            }
        }
    );

    return camelcaseKeys(data);
};

export const translateLanguages = async (languages) => {
    try {
        const { data } = await axios.post(`${urlPath()}/misc/translate-languages`, {
            languages,
            langTo: currentLang()
        });

        return data;
    } catch (e) {
        return languages.map((l) => {
            l.name = l;
            return l;
        });
    }
};
