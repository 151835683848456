import { Colors, Lightning, Router, Storage } from "@lightningjs/sdk";
import { MENU_PAGE_IDS, POSTER_HORIZONTAL_DIMENSIONS, POSTER_VERTICAL_DIMENSIONS } from "../lib/utils";
import { handleMovieSelect, PLATFORMS } from "../lib/helpers";
import TranslatableText from "../components/TranslatableText/TranslatableText";
import { MovieList } from "../components";
import { appPlatformKey } from "../lib/storage";

export default class Home extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            w: 1920,
            h: 1080,
            color: Colors("pageBackground").get(),
            collision: true,
            Page: {
                x: 112,
                w: 1920,
                h: 1080,
                collision: true,
                ContinueWatchingLabel: {
                    y: 32,
                    type: TranslatableText,
                    text: {
                        fontStyle: 500,
                        fontSize: 24
                    }
                },
                RecentlyWatchedList: {
                    y: 76,
                    x: 0,
                    type: MovieList,
                    collision: true
                },
                ContinueTitle: {
                    y: 660,
                    type: TranslatableText,
                    key: "topWatched",
                    text: {
                        fontStyle: 500,
                        fontSize: 24
                    }
                },
                List: {
                    y: 704,
                    type: MovieList,
                    collision: true
                }
            }
        };
    }

    set data(data) {
        this._recentlyWatched = data.recentlyWatched && data.recentlyWatched.length ? data.recentlyWatched : null;
        this._topWatched = data.topMovies;

        this._renderCategoryList();

        if (!this.continueWatchingSelected || !this._recentlyWatched) {
            this._setState("ListState");
        } else {
            this._setState("RecentlyWatchedState");
        }
    }

    _active() {
        this.widgets.menu.setActive(MENU_PAGE_IDS.home);
    }

    _getFocused() {
        return this._recentlyWatched ? this.tag("RecentlyWatchedList") : this.tag("List");
    }

    _handleLeft() {
        Router.focusWidget("Menu");
    }

    _handleDown() {
        // Router.navigate("browse");
    }

    _renderCategoryList() {
        if (this._recentlyWatched) {
            this.patch({
                Page: {
                    ContinueWatchingLabel: {
                        key: "continueWatching",
                        alpha: 1
                    },
                    RecentlyWatchedList: {
                        selectedIndex: 0,
                        alpha: 1,
                        itemSpacing: 8,
                        dimensions:
                            Storage.get(appPlatformKey) === PLATFORMS.posttv
                                ? POSTER_HORIZONTAL_DIMENSIONS.equalXxl
                                : POSTER_HORIZONTAL_DIMENSIONS.xxl,
                        vertical: false,
                        isRecentlyWatched: true,
                        data: this._recentlyWatched
                    },
                    ContinueTitle: {
                        y: 660
                    },
                    List: {
                        y: 704,
                        itemSpacing: 8,
                        dimensions:
                            Storage.get(appPlatformKey) === PLATFORMS.posttv
                                ? POSTER_HORIZONTAL_DIMENSIONS.equalS
                                : POSTER_HORIZONTAL_DIMENSIONS.s,
                        vertical: false,
                        data: this._topWatched
                    }
                }
            });
        } else {
            // this._setState("ListState");
            this.patch({
                Page: {
                    ContinueWatchingLabel: { alpha: 0 },
                    RecentlyWatchedList: {
                        alpha: 0
                    },
                    ContinueTitle: {
                        y: 36
                    },
                    List: {
                        y: 80,
                        itemSpacing: 16,
                        dimensions:
                            Storage.get(appPlatformKey) === PLATFORMS.posttv
                                ? POSTER_VERTICAL_DIMENSIONS.equalM
                                : POSTER_VERTICAL_DIMENSIONS.m,
                        vertical: true,
                        data: this._topWatched
                    }
                }
            });
        }
    }

    $onMovieSelect(event) {
        this.continueWatchingSelected = event.continueWatching;
        handleMovieSelect(this.widgets, event);
    }

    static _states() {
        return [
            class RecentlyWatchedState extends this {
                _getFocused() {
                    return this.tag("RecentlyWatchedList");
                }

                _handleDown() {
                    this._setState("ListState");
                }
            },
            class ListState extends this {
                _getFocused() {
                    return this.tag("List");
                }

                _handleUp() {
                    if (this._recentlyWatched) {
                        this._setState("RecentlyWatchedState");
                    }
                }
            }
        ];
    }

    pageTransition() {
        return "crossFade";
    }
}
