import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import TranslatableText from "../../TranslatableText/TranslatableText";

export default class SettingsItem extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            color: Colors("background").get(),
            flex: { direction: "column", justifyContent: "center", alignItems: "center" },
            Icon: {
                w: 30,
                h: 30,
                flexItem: {}
            },
            Username: {
                text: {
                    fontSize: 32,
                    fontStyle: 700,
                    textAlign: "center"
                }
            },
            Label: {
                flexItem: { marginTop: 32 },
                type: TranslatableText,
                text: {
                    fontSize: 32,
                    fontStyle: 700,
                    textAlign: "center"
                }
            }
        };
    }

    set username(value) {
        this.patch({
            Username: {
                text: {
                    text: value
                },
                flexItem: { marginTop: value ? 32 : 0 }
            },
            Label: {
                flexItem: { marginTop: value ? 8 : 32 },
                text: {
                    fontStyle: value ? 400 : 700,
                    fontSize: value ? 30 : 32
                }
            }
        });
    }

    set label(value) {
        this.patch({
            Label: {
                key: value
            }
        });
    }

    set icon(value) {
        this._icon = value;
        this.patch({
            Icon: {
                src: Utils.asset(value)
            }
        });
    }

    _focus() {
        this.patch({
            smooth: {
                color: Colors("focus").get()
            },
            Username: {
                text: {
                    textColor: Colors("black").get()
                }
            },
            Label: {
                text: {
                    textColor: Colors("black").get()
                }
            },
            Icon: {
                src: Utils.asset(this._icon.replace(".", "-active."))
            }
        });
    }

    _unfocus() {
        this.patch({
            smooth: {
                color: Colors("background").get()
            },
            Username: {
                text: {
                    textColor: Colors("white").get()
                }
            },
            Label: {
                text: {
                    textColor: Colors("white").get()
                }
            },
            Icon: {
                src: Utils.asset(this._icon)
            }
        });
    }

    _handleClick() {
        this.fireAncestors("$itemClicked", {
            id: this.parent.assignedID
        });

        this.parent._handleEnter();
    }
}
