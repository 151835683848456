import { Colors, Lightning, Router, Utils } from "@lightningjs/sdk";
import { List } from "@lightningjs/ui";
import MenuItem from "./MenuItem";
import { MENU_PAGE_IDS } from "../../lib/utils";

export default class Menu extends Lightning.Component {
    static _template() {
        return {
            collision: true,
            zIndex: 80,
            w: 64,
            h: 1080,
            Background: {
                alpha: 0,
                w: 0,
                h: 1080,
                rect: true,
                colorLeft: 0xff000000,
                colorRight: Colors("background")
                    .alpha(0)
                    .get()
            },
            Content: {
                rect: true,
                collision: true,
                color: Colors("background").get(),
                w: 64,
                h: 1080,
                clipping: true,
                Logo: {
                    y: 10,
                    x: 8,
                    h: 41,
                    w: 46,
                    clipping: true,
                    Img: {
                        h: 41,
                        w: 165,
                        src: Utils.asset("images/logo.png")
                    }
                },
                MenuItems: {
                    collision: true,
                    type: List,
                    itemType: MenuItem,
                    y: 360,
                    x: 0,
                    direction: "column",
                    signals: { onIndexChanged: "listIndexChanged" }
                }
            }
        };
    }

    _getFocused() {
        return this.tag("MenuItems");
    }

    _init() {
        this._items = [
            {
                data: { label: "home", link: "home", icon: "icons/home.png" },
                id: MENU_PAGE_IDS.home,
                activeId: this._activeId
            },
            {
                data: { label: "browse", link: "browse", icon: "icons/browse.png" },
                id: MENU_PAGE_IDS.browse,
                activeId: this._activeId
            },
            {
                data: { label: "favorites", link: "favorites", icon: "icons/favourites.png" },
                id: MENU_PAGE_IDS.favorites,
                activeId: this._activeId
            },
            {
                data: { label: "search", link: "search", icon: "icons/search.png" },
                id: MENU_PAGE_IDS.search,
                activeId: this._activeId
            },
            {
                data: { label: "settings", link: "settings", icon: "icons/settings.png" },
                id: MENU_PAGE_IDS.settings,
                activeId: this._activeId
            }
        ];

        const { platform } = Router.getQueryStringParams();

        if (platform === "posttv") {
            this._items.push({
                data: { label: "exitNavigation", link: "exit", icon: "icons/exit.png" },
                id: MENU_PAGE_IDS.exit,
                activeId: this._activeId
            });
        }

        this.tag("MenuItems").add(this._items);

        for (const wrapper of this.tag("MenuItems").children) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    _focus() {
        this._focused = true;
        this.patch({
            Background: {
                smooth: {
                    alpha: 1,
                    w: 1920
                }
            },
            Content: {
                smooth: { w: 279 },
                Logo: {
                    smooth: {
                        w: 165
                    }
                },
                MenuItems: {
                    items: this._items.map(i => {
                        i.activeId = null;
                        return i;
                    })
                }
            }
        });

        this._setState("ActiveState");

        this.tag("MenuItems").setIndex(this._items.findIndex(i => i.id === this._activeId));
    }

    _unfocus() {
        this._focused = false;
        this.patch({
            Background: {
                smooth: {
                    alpha: 0,
                    w: 0
                }
            },
            Content: {
                smooth: { w: 64 },
                Logo: {
                    smooth: {
                        w: 46
                    }
                },
                MenuItems: {
                    items: this._items.map(i => {
                        i.activeId = this._activeId;
                        return i;
                    })
                }
            }
        });
    }

    _handleClick() {
        Router.focusWidget("Menu");
    }

    _handleLeft() {
        Router.focusWidget("Menu");
    }

    _handleDown() {
        Router.focusWidget("Menu");
    }

    _handleUp() {
        Router.focusWidget("Menu");
    }

    setActive(id) {
        this._activeId = id;

        this.patch({
            Content: {
                MenuItems: {
                    items: this._items.map(i => {
                        i.activeId = id;
                        return i;
                    })
                }
            }
        });
    }

    resetPositions() {
        this.application.emit("menuPageSelected");
    }

    _onActivated() {
        if (this._focused) {
            Router.focusPage();
        }
    }

    static _states() {
        return [
            class ActiveState extends this {
                $enter() {
                    this.tag("MenuItems").first();
                }
                _getFocused() {
                    return this.tag("MenuItems");
                }
            }
        ];
    }
}
