import { Colors, Lightning, Registry, Router, Storage } from "@lightningjs/sdk";
import { CategoryList } from "../components";
import { Column } from "@lightningjs/ui-components";
import { MENU_PAGE_IDS, pageTransition } from "../lib/utils";
import { handleMovieSelect, PLATFORMS } from "../lib/helpers";
import { appPlatformKey } from "../lib/storage";

export default class Browse extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            collision: true,
            w: 1920,
            h: 1080,
            color: Colors("pageBackground").get(),
            CategoryList: {
                collision: true,
                type: Column,
                lazyScroll: true,
                // lazyUpCount: 2,
                itemSpacing: 24,
                y: 32,
                x: 112,
                clipping: true,
                w: 1808,
                // h: 1108,
                h: 1048,
                itemTransition: {
                    duration: 0.55,
                    timingFunction: "cubic-bezier(0.20, 1.00, 0.30, 1.00)"
                }
            }
        };
    }

    pageTransition(pageIn) {
        return pageTransition(pageIn);
    }

    set data(data) {
        this.recentlyWatched = data.find(cat => cat.recentlyWatched);
        this._categories = data
            .filter(cat => !cat.recentlyWatched)
            .map(d => {
                if (this._position && d.categoryId === this._position.data.category.categoryId) {
                    d.selectedIndex = this._position.selectedIndex;
                }
                return d;
            });
    }

    _getFocused() {
        return this.tag("CategoryList");
    }

    _init() {
        this.listeners = {
            menuPageSelected: () => {
                this._handleMenuPageSelected();
            }
        };

        if (Storage.get(appPlatformKey) === PLATFORMS.posttv) {
            this.patch({
                CategoryList: {
                    itemTransition: {
                        duration: 0
                    }
                }
            });
        }
    }

    _attach() {
        ["menuPageSelected"].forEach(event => {
            this.application.on(event, this.listeners[event]);
        });
    }

    _detach() {
        ["menuPageSelected"].forEach(event => {
            this.application.off(event, this.listeners[event]);
        });
    }

    _handleMenuPageSelected() {
        this._position = null;
        this._selectedIndex = 0;
    }

    _active() {
        this.widgets.menu.setActive(MENU_PAGE_IDS.browse);

        this._categories = this._categories.map(d => {
            if (this._position && d.categoryId === this._position.data.category.categoryId) {
                d.selectedIndex = this._position.selectedIndex;
            }
            return d;
        });

        this._renderCategoryList();

        this.tag("CategoryList").selectedIndex = this._selectedIndex || 0;

        if (this._selectedIndex <= this._categories.length - 1) {
            this.tag("CategoryList").render();
        }
    }

    _handleLeft() {
        Router.focusWidget("Menu");
    }

    _handleUp() {
        // Router.navigate("home");
    }

    _handleBack() {
        Router.navigate("home");
    }

    _renderCategoryList() {
        this.patch({
            CategoryList: {
                items: this._categories.map((c, index) => {
                    return {
                        type: CategoryList,
                        category: c,
                        h: Storage.get(appPlatformKey) === PLATFORMS.posttv ? 510 : 460,
                        index
                    };
                })
            }
        });
    }

    $onMovieSelect(event) {
        this._selectedIndex = this.tag("CategoryList").selectedIndex;

        this._position = event;

        handleMovieSelect(this.widgets, event);
    }
}
